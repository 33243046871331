import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { urls } from "../../../api/apiUrl";
import { makeApiCall } from "../../../api/config";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEducation,
  setUser,
} from "../../../redux/features/user/userSlice";
import { toast } from "react-toastify";
import { isUserMentor } from "../../../utils/helper";

export default function AcceptOrRejectMeetModal({
  isOpen,
  setIsOpen,
  status,
  selectedMeetForAcceptOrReject,
  getPendingSessions,
}) {
  const dispatch = useDispatch();
  let authData = JSON.parse(localStorage.getItem("tokens"));
  function closeModal() {
    setIsOpen(false);
  }

  const isMentor = isUserMentor();

  const getProfileData = async (token) => {
    await makeApiCall(
      "GET",
      isMentor ? urls.mentorMe : urls.userDetails,
      null,
      authData?.access?.token
    )
      .then((data) => {
        dispatch(setUser(data));
      })
      .catch((error) => {
        const { response } = error;
        // console.log(response);
      });
  };

  const updateStatus = async (data) => {
    try {
      await makeApiCall(
        "POST",
        urls.updateAppointmentStatus(selectedMeetForAcceptOrReject),
        { status },
        authData?.access?.token
      );
      getProfileData();
      getPendingSessions();
      setIsOpen(false);
      toast.success(
        `Meet ${status === "ACCEPTED" ? "Accepted" : "Rejected"} successfully!`
      );
    } catch (error) {
      const { response } = error;
      toast.error(
        `Failed to ${status === "ACCEPTED" ? "Accept" : "Reject"} meet.`
      );
      // console.log(response);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {status === "ACCEPTED" ? "Accept" : "Reject"} Meet
                </Dialog.Title>
                <div className="mt-2 ">
                  <p className="text-sm font-light">
                    Are you sure! you want to{" "}
                    {status === "ACCEPTED" ? "Accept" : "Reject"}?
                  </p>
                  <div className="flex justify-end gap-2 mt-3">
                    <span
                      onClick={closeModal}
                      className="px-3 py-2 text-sm text-[#EB5757]] rounded-lg cursor-pointer "
                    >
                      No
                    </span>
                    <span onClick={updateStatus} className="bg-[#EB5757] text-white text-sm px-3 py-2 rounded-lg cursor-pointer">
                      Yes, I want to
                    </span>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
