import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { formatDate, isUserMentor } from "../utils/helper";
import ShowDetailModal from "../pages/HomePage/ProfilePage/ShowDetailModal";
import { makeApiCall } from "../api/config";
import { urls } from "../api/apiUrl";
import Layout from "../pages/HomePage/Layout";
import Loader from "./Loader";

const MeetsHistory = () => {
  let authData = JSON.parse(localStorage.getItem("tokens"));
  const [showAllPast, setShowAllPast] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedForDetails, setSelectedForDetails] = useState({});
  const [pastSessions, setPastSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const displayedPastSessions = showAllPast
    ? pastSessions
    : pastSessions.slice(0, 3);

  const toggleShowAllPast = () => {
    setShowAllPast(!showAllPast);
  };

  const isMentor = isUserMentor();
  const renderName = (item) => {
    return !isMentor
      ? `${item?.consultantID?.firstName} ${item?.consultantID?.lastName}`
      : `${item?.userID?.firstName} ${item?.userID?.lastName}`;
  };

  const getPastSession = async () => {
    setIsLoading(true);
    await makeApiCall(
      "GET",
      urls.mentorCompletedSessions,
      null,
      authData?.access?.token
    )
      .then((data) => {
        setPastSessions(data?.appointments);
      })
      .catch((error) => {
        const { response } = error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPastSession();
  }, []);

  return (
    <Layout>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="mx-10 mt-5">
          <div className="flex flex-row items-center justify-between w-full pb-4 ">
            <p className="text-lg font-medium text-black">Past Sessions</p>
            <p
              className="text-[#3366CC] underline font-normal cursor-pointer"
              onClick={toggleShowAllPast}
            >
              {showAllPast ? "Show Less" : "See All"}
            </p>
          </div>
          <div className="overflow-x-auto rounded-lg shadow-md">
            <table className="min-w-full bg-white ">
              <thead>
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-[#3F3F3F] uppercase tracking-wider">
                    User Name & Date
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-[#3F3F3F] uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-[#3F3F3F] uppercase tracking-wider">
                    Slot/Time
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-[#3F3F3F] uppercase tracking-wider">
                    Pay
                  </th>
                </tr>
              </thead>
              <tbody className="px-2 bg-white ">
                {displayedPastSessions.map((item, index) => (
                  <tr key={index} className="py-2">
                    <td className="px-4 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <img
                          className="w-10 h-10 rounded-full"
                          src={
                            item?.consultantID?.profile ||
                            item?.userID?.profile ||
                            !isMentor
                              ? "/assets/consultant.jpg"
                              : "https://img.freepik.com/premium-vector/man-professional-business-casual-young-avatar-icon-illustration_1277826-632.jpg"
                          }
                          alt=""
                        />
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {renderName(item)}
                          </div>
                          <div className="text-sm text-gray-500">
                            {formatDate(item.date)}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-0 py-4 whitespace-nowrap">
                      <span className="inline-flex text-xs leading-5 font-normal px-4 rounded-full bg-red-100 text-[#EB5757] capitalize">
                        {item.status}
                      </span>
                    </td>
                    <td className="px-0 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {item?.slot} - {item?.minutes} mins
                    </td>
                    <td className="px-0 py-4 text-sm font-medium whitespace-nowrap">
                      <button className="bg-[#3366CC33] uppercase rounded-md  py-1 px-4 text-sm font-semibold text-[#3366CC]">
                        ₹ {item?.pay}
                      </button>
                    </td>
                  </tr>
                ))}
                {displayedPastSessions.length <= 0 && (
                  <tr>
                    <td colSpan="4" className="py-4 text-xs text-center">
                      No Data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <ShowDetailModal
            isOpen={openDetailsModal}
            setIsOpen={setOpenDetailsModal}
            selectedForDetails={selectedForDetails}
          />
        </div>
      )}
    </Layout>
  );
};

export default MeetsHistory;
