import React, { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { urls } from "../../api/apiUrl";

const SignupPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [otp, setOtp] = useState("");
  const [isOtpDisabled, setIsOtpDisabled] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [isResendOtp, setIsResendOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOtpVerificationMode, setIsOtpVerificationMode] = useState(false);
  const handleSendOtp = async () => {
    if (!/^\d{10}$/.test(phoneNumber)) {
      setPhoneError("Please enter a 10-digit number");
      return;
    }
    setPhoneError("");
    setErrorMessage("");

    try {
      const apiUrl = isResendOtp ? urls.resendOtp : urls.sendOtp;

      let requestBody = {
        phoneNumber,
        countryCode,
      };

      if (!isResendOtp) {
        requestBody = {
          ...requestBody,
          email,
          password,
        };
      }

      const response = await axios.post(apiUrl, requestBody);

      if (response.status === 200 || response.status === 201) {
        setIsOtpDisabled(false);
        setIsResendOtp(true);
        setIsOtpVerificationMode(true);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message || "An error occurred");
        setIsResendOtp(true);
      }
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 3000);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(urls.verifyOtp, {
        phoneNumber,
        countryCode,
        otp,
      });

      if (response.status === 200 || response.status === 201) {
        // Store the response data in localStorage
        navigate("/profile");
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("tokens", JSON.stringify(response.data.tokens));

        // You can add additional logic here, such as redirecting the user or showing a success message
        console.log("OTP verified successfully");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage(error.response?.data?.message || "Failed to verify OTP");
    }
  };


  const loginWithGoogle = () => {
    window.open("https://api.askitall.co/auth/google/callback", "_self");
  }

  return (
    <div className="min-h-screen text-black bg-white ">
      <div className="h-[60vh] w-full bg-[#EB5757] px-6 ">
        <div className="flex flex-col h-full px-6 ">
          <div className="py-3">
            <Link to="/">
              <h1 className="text-xl font-bold text-transparent bg-white bg-clip-text">
                ASK-IT-ALL
              </h1>
            </Link>
          </div>
          <div className="flex h-full">
            <div className="w-[26%] flex flex-col mt-20">
              <p className="mb-2 text-4xl font-semibold text-white">
                Sign Up to{" "}
              </p>
              <p className="text-lg font-light text-white">
              Explore our services through our ease-to-use platform
              </p>
              <p className="mt-4 text-sm font-light text-white">
              Count on Ask It All for expert financial and legal guidance, available 24/7. Whether you're strategizing for long-term goals or resolving immediate concerns, get hold of our services to gain the clarity and confidence needed to progress.
From planning to execution, we've got you covered.
              </p>
            </div>
            <div>
              <img
                src="/assets/login.png"
                className="w-[350px] h-[350px] object-contain"
                alt="vector"
              />
            </div>
          </div>
        </div>
        <div className="absolute p-10 bg-white shadow-md rounded-3xl top-20 right-24 w-[520px]">
          <Heading />
          <button
            className="flex flex-row items-center bg-[#E9F1FF] px-4 py-2 gap-3 rounded-md w-full justify-center mt-6 transition duration-300 ease-in-out hover:border hover:border-[#4285F4]" 
            onClick={loginWithGoogle}
          >
            <img src="/assets/google.png" alt="google" className="w-5 h-5" />
            <p className="text-[#4285F4] text-sm">Sign Up With Google</p>
          </button>
          {phoneError && (
            <div
              className="relative px-4 py-3 mb-4 text-red-700 bg-red-100 border border-red-400 rounded"
              role="alert"
            >
              <span className="block sm:inline">{phoneError}</span>
            </div>
          )}
          {errorMessage && (
            <div
              className="relative px-4 py-3 mb-4 text-yellow-700 bg-yellow-100 border border-yellow-400 rounded"
              role="alert"
            >
              <span className="block sm:inline">{errorMessage}</span>
            </div>
          )}
          <Email
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            otp={otp}
            setOtp={setOtp}
            isOtpDisabled={isOtpDisabled}
            handleSendOtp={handleSendOtp}
            showTooltip={showTooltip}
            isResendOtp={isResendOtp}
            handleVerifyOtp={handleVerifyOtp}
            isOtpVerificationMode={isOtpVerificationMode}
          />
          {/* <Terms /> */}
        </div>
      </div>
      <div className="h-[40vh] w-full bg-white"></div>
    </div>
  );
};

const Heading = () => (
  <div>
    <div className="flex items-center justify-between">
      <div className="space-y-1">
        <h1 className="text-lg font-normal">
        Welcome to <span className="text-[#EB5757] font-semibold">ASK-IT-ALL</span>
        </h1>
        <h1 className="text-4xl font-semibold">Sign up</h1>
      </div>
      <div className="space-y-1">
        <h1 className="text-sm font-normal text-[#8D8D8D]">
          Have an Account ?
        </h1>
        <Link to="/login">
          <h1 className="text-sm font-normal text-[#EB5757]">Sign in</h1>
        </Link>
      </div>
    </div>
  </div>
);

const Or = () => {
  return (
    <div className="flex items-center gap-3 my-6">
      <div className="h-[1px] w-full bg-zinc-700" />
      <span className="text-zinc-400">OR</span>
      <div className="h-[1px] w-full bg-zinc-700" />
    </div>
  );
};

const Email = ({
  email,
  setEmail,
  password,
  setPassword,
  phoneNumber,
  setPhoneNumber,
  countryCode,
  setCountryCode,
  otp,
  setOtp,
  isOtpDisabled,
  handleSendOtp,
  showTooltip,
  isResendOtp,
  isOtpVerificationMode,
  handleVerifyOtp,
}) => {
  return (
    <form onSubmit={(e) => e.preventDefault()} className="mt-10">
      <div className="mb-6">
        <label
          htmlFor="email-input"
          className="mb-2 block text-[#000000] text-base"
        >
          Email
        </label>
        <input
          id="email-input"
          type="email"
          placeholder="your.email@provider.com"
          className="w-full px-3 py-3 transition-shadow bg-white border text-sm border-[#ADADAD] rounded-md b placeholder-[#ADADAD] ring-1 ring-transparent"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mb-6">
        <div className="flex items-end justify-between mb-2">
          <label
            htmlFor="password-input"
            className="block mb-2 text-[#000000] text-base"
          >
            Password
          </label>
        </div>
        <input
          id="password-input"
          type="password"
          placeholder="••••••••••••"
          className="w-full px-3 py-3 transition-shadow bg-white border text-sm border-[#ADADAD] rounded-md b placeholder-[#ADADAD] ring-1 ring-transparent"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="grid w-full grid-cols-1 lg:grid-cols-3 gap-x-2">
        <div className="mb-3">
          <label
            htmlFor="phone-input"
            className=" block mb-2 text-[#000000] text-base"
          >
            Contact Number
          </label>
          <div className="flex w-full">
            <input
              id="phone-input"
              type="tel"
              placeholder="88888 88888"
              className="w-full px-3 py-3 transition-shadow bg-white border text-sm border-[#ADADAD] rounded-md b placeholder-[#ADADAD] ring-1 ring-transparent"
              value={phoneNumber}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "");
                setPhoneNumber(value.slice(0, 10));
              }}
            />
          </div>
        </div>
        <div className="relative mb-6">
          <div className="flex items-end justify-between mb-2">
            <label
              htmlFor="otp-input"
              className="block text-[#000000] text-base"
            >
              OTP
            </label>
          </div>
          <input
            id="otp-input"
            type="text"
            placeholder="••••"
            className="w-full px-3 py-3 transition-shadow bg-white border text-sm border-[#ADADAD] rounded-md b placeholder-[#ADADAD] ring-1 ring-transparent"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            disabled={isOtpDisabled}
          />
          {/* {showTooltip && (
            <div className="absolute top-0 left-0 px-2 py-1 mb-2 text-xs text-white bg-red-500 rounded bottom-full">
              Server is busy. Please try again later.
            </div>
          )} */}
        </div>
        <button
          type="button"
          className="w-full text-white h-11 my-auto rounded-md bg-[#EB5757]"
          onClick={handleSendOtp}
        >
          {isResendOtp ? "Resend OTP" : "Send OTP"}
        </button>
      </div>
      <SplashButton
        type="button"
        className="w-full mt-4"
        onClick={isOtpVerificationMode ? handleVerifyOtp : handleSendOtp}
      >
        {isOtpVerificationMode ? "Verify OTP" : "Sign up"}
      </SplashButton>
    </form>
  );
};

const Terms = () => (
  <p className="text-xs mt-9 text-zinc-400">
    By signing in, you agree to our{" "}
    <a target="_blank" href="/termsandconditions" className="text-[#EB5757]">
      Terms & Conditions
    </a>{" "}
    and{" "}
    <a target="_blank" href="/refundPolicy" className="text-[#EB5757]">
      Refund Policy.
    </a>
  </p>
);

const SplashButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={twMerge(
        "rounded-md bg-gradient-to-br from-[#EB5757] to-[#EB5757] px-4 py-2 text-lg text-zinc-50   transition-all hover:scale-[1.02] hover:ring-transparent active:scale-[0.98] ",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

const BubbleButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={twMerge(
        `
        relative z-0 flex items-center gap-2 overflow-hidden whitespace-nowrap rounded-md shadow-inner bg-[#F7F7F7]
        px-3 py-1.5
        text-black transition-all duration-500 delay-200 ease-in-out
        

        hover:scale-105  hover:text-zinc-900
        hover:before:translate-y-[0%]
        active:scale-100`,
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export default SignupPage;
