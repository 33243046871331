import React, { useEffect, useState } from "react";
import Layout from "../HomePage/Layout";
import { StarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { urls } from "../../api/apiUrl";
import { makeApiCall } from "../../api/config";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setTotalCreditsAvailable,
  setUser,
} from "../../redux/features/user/userSlice";
import Loader from "../../components/Loader";
import ButtonLoader from "../../components/ButtonLoader";
import { isUserMentor } from "../../utils/helper";

const AdvisorBooking = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let authData = JSON.parse(localStorage.getItem("tokens"));
  const [duration, setDuration] = useState(10);
  const [consultant, setConsultant] = useState();
  const [date, setDate] = useState("");
  const [dateSlots, setDateSlots] = useState([]);
  const [question, setQuestion] = useState("");
  const { user } = useSelector((state) => state.user);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [questionError, setQuestionError] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [consultantDates, setConsultantDates] = useState([]);

  const [totalCost, setTotalCost] = useState();

  const handleDurationChange = (newDuration: number) => {
    setDuration(newDuration);
    setTotalCost(newDuration * consultant?.chargePerMinutes);
  };

  const isMentor = isUserMentor();

  const handleChange = (event) => {
    setSelectedSlot(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
    setQuestionError(false);
  };
  
  const getAdvisorById = async () => {
    setIsLoading(true);
    await makeApiCall(
      "GET",
      urls.getConsultantsById(id),
      null,
      authData?.access?.token
    )
      .then(({ data }) => {
        setTotalCost(data?.chargePerMinutes * duration);
        setConsultant(data);
      })
      .catch((error) => {
        const { response } = error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAdvisorDatesById = async () => {
    await makeApiCall(
      "GET",
      urls.getConsultantsDatesById(id),
      null,
      authData?.access?.token
    )
      .then(({ dates }) => {
        setDate(dates[0]);
        setConsultantDates(dates);
      })
      .catch((error) => {
        const { response } = error;
      })
      .finally(() => {});
  };

  useEffect(() => {
    getAdvisorDatesById();
  }, []);

  const getSlots = async () => {
    await makeApiCall(
      "GET",
      urls.getAvailableSlots(id, date),
      null,
      authData?.access?.token
    )
      .then(({ slots }) => {
        const currentTime = new Date();

      const filteredSlots = slots.filter(({ slot }) => {
        const [time, period] = slot.split(" "); 
        const [hours, minutes] = time.split(":").map(Number);

        let slotDate = new Date();
        slotDate.setHours(period === "PM" && hours !== 12 ? hours + 12 : hours === 12 && period === "AM" ? 0 : hours);
        slotDate.setMinutes(minutes);
        slotDate.setSeconds(0);

        return slotDate > currentTime;
      });

      if (filteredSlots.length > 0) {
        setSelectedSlot(filteredSlots[0].slot);
        setDateSlots(filteredSlots);
      } else {
          setDateSlots([]);
        }
      })
      .catch((error) => {
        const { response } = error;
      });
  };

  useEffect(() => {
    if (date) getSlots();
  }, [date]);

  useEffect(() => {
    if (id) getAdvisorById();
  }, [id]);

  useEffect(() => {
    if (id) getAdvisorDatesById();
  }, [id]);

  const updateProfileData = async (data) => {
    await makeApiCall(
      "PUT",
      `${urls.updateDetails}`,
      data,
      authData?.access?.token
    )
      .then((data) => {
        dispatch(setUser(data));
      })
      .catch((error) => {
        const { response } = error;
        // console.log(response);
      });
  };

  const handleBooking = async () => {
    setIsBooking(true);
    if (!question || !selectedSlot || !date || !duration) {
      setIsBooking(false);
      toast.error("Please fill all the details");
      return;
    }
    const formattedDate = new Date(date).toISOString();

    const data = {
      slot: selectedSlot,
      date: formattedDate,
      consultantID: id,
      minutes: duration,
      question,
      pay: totalCost,
    };

    if(user?.totalCreditsAvailable >= totalCost){
      try {
        await makeApiCall("POST", `${urls.book}`, data, authData?.access?.token);
        updateProfileData({
          totalCreditsAvailable: user?.totalCreditsAvailable - totalCost,
        });
        navigate("/profile");
        toast.success("Booking Successfull!");
      } catch (error) {
        toast.error("Booking Failed!");
      }
    }
    else{
      toast.error("You doesn't have enough credits! Add credits and try again");
      navigate('/credits')
    }
    setIsBooking(false);
  };

  return (
    <Layout>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-6 mx-12 mt-10 ">
          <div className="grid grid-cols-2 mb-6 border-b border-b-[#EBEBEB] pb-4">
            <div className="flex items-center space-x-4">
              <img
                className="mr-4 rounded-full w-36 h-36"
                src={
                  consultant?.profile || !isMentor
                    ? "/assets/consultant.jpg"
                    : "https://img.freepik.com/premium-vector/man-professional-business-casual-young-avatar-icon-illustration_1277826-632.jpg"
                }
                alt="Profile"
              />
              <div>
                <h2 className="text-xl font-normal text-black">
                  {consultant?.firstName} {consultant?.lastName}
                </h2>
                <p className="text-sm text-[#5F5F5F]">
                  {consultant?.specialization}
                </p>
                <p className="mb-2 text-sm text-[#5F5F5F]">
                  Experience - {consultant?.experience} Years
                </p>
                <div className="flex items-center mb-2">
                  {[...Array(5)].map((_, i) => (
                    <StarIcon
                      key={i}
                      className="w-4 h-4 text-yellow-400 fill-current"
                    />
                  ))}
                </div>
                <div className="space-y-1">
                  <p className="text-sm text-[#5F5F5F]">
                    {consultant?.minutesCompleted} minutes completed
                  </p>
                  <p className="text-sm text-[#5F5F5F]">
                    Advised {consultant?.candidatesAdvised} candidates
                  </p>
                  <p className="text-sm font-medium text-black">
                    ₹{consultant?.chargePerMinutes}/- per minute
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-6">
              <p className="mb-2 font-normal text-black">Question :</p>
              <textarea
                className="w-full p-2 border rounded-md focus:outline-none focus:border-[#EB5757]"
                rows={5}
                value={question}
                onChange={handleQuestionChange}
                placeholder="Enter your question here..."
              />
              {questionError && (
                <p className="text-xs text-red-500">Please enter a question</p>
              )}
            </div>
          </div>

          <div className="mb-6">
            <p className="mb-2 font-normal text-black">Time :</p>
            <div className="relative pt-1">
              <input
                type="range"
                min="10"
                max="40"
                step="10"
                value={duration}
                onChange={(e) => handleDurationChange(Number(e.target.value))}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer "
                style={{
                  background: `linear-gradient(to right, #EB5757 ${
                    ((duration - 10) / (40 - 10)) * 100
                  }%, #D1D5DB ${((duration - 10) / (40 - 10)) * 100}%)`,
                }}
              />
              <div className="flex justify-between px-2 mt-2 text-xs text-[#5F5F5F]">
                <span>10 minutes</span>
                <span>20 minutes</span>
                <span>30 minutes</span>
                <span>40 minutes</span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex flex-col justify-center gap-8  w-[30%]">
              <div className="relative">
                <p className="mb-2 font-normal text-black">Book Slot :</p>
                {/* <input
                  id="date"
                  type="date"
                  value={date ? new Date(date).toISOString().slice(0, 10) : ""}
                  onChange={(e) => setDate(e.target.value)}
                  className="w-full px-3 py-2 placeholder-gray-400 border rounded-md focus:outline-none focus:border-[#EB5757]"
                /> */}

                {consultantDates.length > 0 ? (
                  <select
                    className="w-full px-3 py-2 pr-8 bg-white border rounded-md focus:border-[#EB5757]"
                    value={date}
                    onChange={handleDateChange}
                  >
                    {consultantDates.map((slot) => (
                      <option key={slot} value={slot}>
                        {slot}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p className="text-sm text-[#5F5F5F]">
                    There is no dates available!!
                  </p>
                )}
              </div>
              <div className="relative">
                <p className="mb-2 font-normal text-black">Timing :</p>
                {dateSlots.length > 0 ? (
                  <select
                    className="w-full px-3 py-2 pr-8 bg-white border rounded-md focus:border-[#EB5757]"
                    value={selectedSlot}
                    onChange={handleChange}
                  >
                    {dateSlots.map((slot) => (
                      <option key={slot.slot} value={slot.slot}>
                        {slot.slot}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p className="text-sm text-[#5F5F5F]">
                    There is no slots available!!
                  </p>
                )}
              </div>
              <div className="flex items-center ">
                <p className="font-medium">Total Cost :</p>
                <p className="ml-3 text-xl font-medium">₹{totalCost}</p>
              </div>
            </div>

            <div className="self-end">
              <button
                onClick={handleBooking}
                className=" px-12 py-2 text-white transition duration-300 bg-[#EB5757] rounded-md hover:bg-red-600"
                disabled={isBooking}
              >
                {isBooking ? <ButtonLoader /> : "Book"}
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default AdvisorBooking;
