import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { formatDate, isUserMentor } from "../../../utils/helper";

const RenderText = ({ label, text }) => {
  return (
    <div>
      <p className="text-xs  text-[#747474]">{label}</p>
      <p className="text-base font-medium text-black capitalize">{text}</p>
    </div>
  );
};

export default function ShowDetailModal({
  isOpen,
  setIsOpen,
  selectedForDetails,
}) {
  function closeModal() {
    setIsOpen(false);
  }

  const consultant = selectedForDetails?.consultantID;
  const user = selectedForDetails?.userID;
  const isMentor = isUserMentor();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold leading-6 text-gray-900"
                >
                  Session Details
                </Dialog.Title>

                <div className="grid grid-cols-2 mt-4 gap-y-4">
                  {isMentor ? (
                    <RenderText
                      label="User Name"
                      text={`${user?.firstName}  ${user?.lastName}`}
                    />
                  ) : (
                    <RenderText
                      label="Consultant Name"
                      text={`${consultant?.firstName}  ${consultant?.lastName}`}
                    />
                  )}
                  <RenderText
                    label="Date"
                    text={formatDate(selectedForDetails?.date)}
                  />
                  <RenderText
                    label="Question"
                    text={selectedForDetails?.question}
                  />
                  <RenderText label="Slot" text={selectedForDetails?.slot} />
                  <RenderText
                    label="Status"
                    text={selectedForDetails?.status}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
