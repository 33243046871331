import { urls } from "../api/apiUrl";
import { makeApiCall } from "../api/config";
import { isUserMentor } from "../utils/helper";

const tokenProvider = async (authToken) => {
  const isMentor = isUserMentor();

  try {
    const response = await makeApiCall(
      "GET",
      isMentor ? urls.mentorMeetToken : urls.meetToken,
      null,
      authToken
    );

    const token = response;
    if (!token) {
      throw new Error("Token not found in the response");
    }

    return token;
  } catch (error) {
    console.error("Error fetching token:", error);
    throw error;
  }
};

export default tokenProvider;
