import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { urls } from "../../../api/apiUrl";
import { makeApiCall } from "../../../api/config";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/features/user/userSlice";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(50, "First name must not exceed 50 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters")
    .max(50, "Last name must not exceed 50 characters"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address")
    .max(100, "Email must not exceed 100 characters"),
  image: Yup.mixed()
    .required("Image is required")
    .test("image-size", "Image size should be less than 2MB", (value) => {
      if (value) {
        return value.size <= 2000000;
      }
      return true;
    })
    .test("image-type", "Only image files are accepted", (value) => {
      if (value) {
        return ["image/jpeg", "image/jpg", "image/png", "image/gif"].includes(
          value.type
        );
      }
      return true;
    }),
});

export default function EditProfileDetails({ isOpen, setIsOpen }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  let authData = JSON.parse(localStorage.getItem("tokens"));
  let token = useSelector((state) => state?.user?.token);
  function closeModal() {
    setIsOpen(false);
  }

  const initialValues = {
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    email: user.email || "",
    image: null,
  };

  const updateProfileData = async (data) => {
    await makeApiCall(
      "PUT",
      `${urls.updateDetails}`,
      data,
      authData?.access?.token
    )
      .then((data) => {
        dispatch(setUser(data));
        setIsOpen(false);
        toast.success("Profile updated successfully");
      })
      .catch((error) => {
        const { response } = error;
        // console.log(response);
        toast.error("Failed to update profile");
      });
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };

    if (token || authData?.access?.token) {
      await updateProfileData(data);
    }

    setSubmitting(false);
    resetForm();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Edit Profile
                </Dialog.Title>
                <div className="mt-4 ">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched, isSubmitting, setFieldValue }) => (
                      <Form className="space-y-4">
                        <div>
                          <label
                            htmlFor="firstName"
                            className="block mb-1 text-sm font-medium text-gray-700"
                          >
                            First Name
                          </label>
                          <Field
                            id="firstName"
                            name="firstName"
                            type="text"
                            placeholder="Enter first name"
                            className={`w-full px-3 py-2 placeholder-gray-400 border rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 ${
                              errors.firstName && touched.firstName
                                ? "border-red-500"
                                : "border-gray-300"
                            }`}
                          />
                          <ErrorMessage name="firstName">
                            {(msg) => (
                              <div className="mt-1 text-sm text-red-500">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div>
                          <label
                            htmlFor="lastName"
                            className="block mb-1 text-sm font-medium text-gray-700"
                          >
                            Last Name
                          </label>
                          <Field
                            id="lastName"
                            name="lastName"
                            type="text"
                            placeholder="Enter last name"
                            className={`w-full px-3 py-2 placeholder-gray-400 border rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 ${
                              errors.lastName && touched.lastName
                                ? "border-red-500"
                                : "border-gray-300"
                            }`}
                          />
                          <ErrorMessage name="lastName">
                            {(msg) => (
                              <div className="mt-1 text-sm text-red-500">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div>
                          <label
                            htmlFor="email"
                            className="block mb-1 text-sm font-medium text-gray-700"
                          >
                            Email
                          </label>
                          <Field
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Enter email"
                            className={`w-full px-3 py-2 placeholder-gray-400 border rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 ${
                              errors.email && touched.email
                                ? "border-red-500"
                                : "border-gray-300"
                            }`}
                          />
                          <ErrorMessage name="email">
                            {(msg) => (
                              <div className="mt-1 text-sm text-red-500">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div>
                          <label
                            htmlFor="image"
                            className="block mb-1 text-sm font-medium text-gray-700"
                          >
                            Profile Image
                          </label>
                          <input
                            id="image"
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              const selectedFile = event.target.files[0];
                              if (selectedFile) {
                                setFieldValue("image", selectedFile);
                              } else {
                                setFieldValue("image", null);
                                setFieldValue("image", "");
                                event.target.value = "";
                              }
                            }}
                            className="w-full px-3 py-2 placeholder-gray-400 border rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                          />
                          <ErrorMessage name="image">
                            {(msg) => (
                              <div className="mt-1 text-sm text-red-500">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#EB5757] hover:bg-[#DE5454] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                            isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                        >
                          {isSubmitting ? "Saving..." : "Save"}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
