import React from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  // Navigate,
} from "react-router-dom";
// import { useDispatch } from "react-redux";
import Homepage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import TermsPage from "./pages/TermsPage";

import Reports from "./pages/HomePage/Reports";
import History from "./pages/HomePage/History";

import AddCredit from "./pages/AddCredit/index";

import PopUp from "./pages/popUp/popUp";

import RefundPolicyPage from "./pages/RefundPolicy";
import Website from "./pages/Website";
import Bookings from "./pages/Bookings/Bookings";
import Advisor from "./pages/Advisor/Advisor";
import TandC from "./components/TandC";
import RefundPolicy from "./components/RefundPolicy";
import Disclaimer from "./components/Disclaimer";
import Contact from "./components/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Profile from "./pages/HomePage/Profile/Profile";
import AdvisorBooking from "./pages/AdvisorBooking/AdvisorBooking";
import MenterLoginPage from "./pages/mentors/MentorLogin";
import MenteeMeetingPage from "./pages/MenteeMeeting/MenteeMeetingPage";
import AcceptPage from "./pages/HomePage/Accept/AcceptPage";
import ProtectedRoute from "./pages/ProtectedRoute";
import ScheduleSettings from "./pages/Schedule/ScheduleSettings";
import MeetsHistory from "./components/MeetsHistory";
import PublicAdvisors from "./pages/public/PublicAdvisors";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Website />} />
        <Route exact path="/contact" element={<Contact />} />

        <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route exact path="/disclaimer" element={<Disclaimer />} />
        <Route exact path="/termsandconditions" element={<TandC />} />
        <Route exact path="/refundPolicy" element={<RefundPolicy />} />
        <Route exact path="/public/advisors" element={<PublicAdvisors />} />

        <Route exact path="/login" element={<LoginPage />} />
        {/* <Route exact path="/mentor/login" element={<MenterLoginPage />} /> */}
        <Route exact path="/website" element={<Website />} />
        <Route exact path="/signup" element={<SignupPage />} />
        <Route exact path="/pop/:consultantId" element={<PopUp />} />
        <Route exact path="/terms" element={<TermsPage />} />
        <Route exact path="/refundpolicy" element={<RefundPolicyPage />} />

        <Route path="/profile" element={<ProtectedRoute element={Profile} />} />
        <Route
          exact
          path="/bookings"
          element={<ProtectedRoute element={Bookings} />}
        />
        <Route
          exact
          path="/advisors"
          element={<ProtectedRoute element={Advisor} />}
        />
        <Route
          exact
          path="/advisors/:id"
          element={<ProtectedRoute element={AdvisorBooking} />}
        />
        <Route
          exact
          path="/meet/:id"
          element={<ProtectedRoute element={MenteeMeetingPage} />}
        />
        <Route
          exact
          path="/accept"
          element={<ProtectedRoute element={AcceptPage} />}
        />
        <Route
          exact
          path="/credits"
          element={<ProtectedRoute element={AddCredit} />}
        />
          <Route
          exact
          path="/history"
          element={<ProtectedRoute element={MeetsHistory} />}
        />
        <Route
          exact
          path="/schedule"
          element={<ProtectedRoute element={ScheduleSettings} />}
        />
        <Route
          exact
          path="/history"
          element={
            <Homepage>
              <History />
            </Homepage>
          }
        />
        <Route
          exact
          path="/report"
          element={
            <Homepage>
              <Reports />
            </Homepage>
          }
        />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
