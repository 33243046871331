import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { urls } from "../../../api/apiUrl";
import { makeApiCall } from "../../../api/config";
import { useDispatch, useSelector } from "react-redux";
import { deleteEducation, setUser } from "../../../redux/features/user/userSlice";
import { toast } from "react-toastify";

export default function DeleteEducationDialog({
  isOpen,
  setIsOpen,
  selectedSchoolForDelete,
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  let authData = JSON.parse(localStorage.getItem("tokens"));
  let token = useSelector((state) => state?.user?.token);
  function closeModal() {
    setIsOpen(false);
  }

  const updateProfileData = async (data) => {
    try {
      await makeApiCall(
        "PUT",
        `${urls.updateDetails}`,
        data,
        authData?.access?.token
      );
      // dispatch(setUser(data));
      setIsOpen(false);
      toast.success("Education details deleted successfully!");
    } catch (error) {
      const { response } = error;
      toast.error("Failed to delete education details.");
      // console.log(response);
    }
  };

  const handleDelete = async () => {
    if (user.education.some((edu) => edu.name === selectedSchoolForDelete)) {
      dispatch(deleteEducation(selectedSchoolForDelete));
  
      const updatedEducation = user.education.filter(
        (edu) => edu.name !== selectedSchoolForDelete
      );
      
      if (token || authData?.access?.token) {
        await updateProfileData({ education: updatedEducation });
      }
    }
  };
  

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Delete Education
                </Dialog.Title>
                <div className="mt-2 ">
                  <p className="text-sm font-light">Are you sure! you want to delete?</p>
                  <div className="flex justify-end gap-2 mt-3">
                    <span onClick={closeModal}
                      className="px-3 py-2 text-sm text-[#EB5757]] rounded-lg cursor-pointer "
                    >
                      No
                    </span>
                    <span onClick={handleDelete}
                      className="bg-[#EB5757] text-white text-sm px-3 py-2 rounded-lg cursor-pointer"
                    >
                      Yes, I want to
                    </span>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
