import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { urls } from "../../api/apiUrl";
import { setUser } from "../../redux/features/user/userSlice";
import { makeApiCall } from "../../api/config";
import { useDispatch } from "react-redux";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const response = await axios.post(urls.login, {
        email,
        password,
      });

      if (response.status === 200 || response.status === 201) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("tokens", JSON.stringify(response.data.tokens));
        navigate("/profile");
      }
    } catch (error) {
      setError("Please provide valid Credentials");
    } finally {
      setIsLoading(false);
    }
  };

  const loginByGoogle = async (userId) => {
    try {
      const response = await axios.post(urls.loginByGoogleAuth(userId));

      if (response.status === 200 || response.status === 201) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("tokens", JSON.stringify(response.data.tokens));
        navigate("/profile");
      }
    } catch (error) {
      setError("Please provide valid Credentials");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("userId");

    if (userId) {
      loginByGoogle(userId);
    }
  }, []);

  const loginWithGoogle = () => {
    window.open("https://api.askitall.co/auth/google/callback", "_self");
  };

  return (
    <div className="min-h-screen text-black bg-white ">
      {error && (
        <div className="absolute z-50 px-4 py-3 text-red-700 transform -translate-x-1/2 bg-red-100 border border-red-400 rounded top-4 left-1/2 backdrop-blur-lg">
          {error}
        </div>
      )}

      <div className="h-[60vh] w-full bg-[#EB5757] px-6 ">
        <div className="flex flex-col h-full px-6 ">
          <div className="py-3">
            <Link to="/">
              <h1 className="text-xl font-bold text-transparent bg-white bg-clip-text">
                ASK-IT-ALL
              </h1>
            </Link>
          </div>
          <div className="flex h-full">
            <div className="w-[26%] flex flex-col mt-20">
              <p className="mb-2 text-4xl font-semibold text-white">
                Sign in to{" "}
              </p>
              <p className="text-lg font-light text-white">
                Explore our services through our ease-to-use platform
              </p>
              <p className="mt-4 text-sm font-light text-white">
                Count on Ask It All for expert financial and legal guidance,
                available 24/7. Whether you're strategizing for long-term goals
                or resolving immediate concerns, get hold of our services to
                gain the clarity and confidence needed to progress. From
                planning to execution, we've got you covered.
              </p>
            </div>
            <div>
              <img
                src="/assets/login.png"
                className="w-[350px] h-[350px] object-contain"
                alt="vector"
              />
            </div>
          </div>
        </div>
        <div className="absolute p-10 bg-white shadow-md rounded-3xl top-32 right-24 min-w-[480px]">
          <Heading />

          <SocialOptions />
          <button
            className="flex flex-row items-center bg-[#E9F1FF] px-4 py-2 gap-3 rounded-md w-full justify-center mt-6 transition duration-300 ease-in-out hover:border hover:border-[#4285F4]" 
            onClick={loginWithGoogle}
          >
            <img src="/assets/google.png" alt="google" className="w-5 h-5" />
            <p className="text-[#4285F4] text-sm">Sign In With Google</p>
          </button>
          <Email
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            handleLogin={handleLogin}
            isLoading={isLoading}
          />
          {/* <Terms /> */}
        </div>
      </div>
      <div className="h-[40vh] w-full bg-white"></div>
    </div>
  );
};

const Heading = () => (
  <div>
    <div className="flex items-center justify-between">
      <div className="space-y-1">
        <h1 className="text-lg font-normal">
          Welcome to{" "}
          <span className="text-[#EB5757] font-semibold">ASK-IT-ALL</span>
        </h1>
        <h1 className="text-4xl font-semibold">LOG IN</h1>
      </div>
      <div className="space-y-1">
        <h1 className="text-sm font-normal text-[#8D8D8D]">No Account ?</h1>
        <Link to="/signup">
          <h1 className="text-sm font-normal text-[#EB5757]">Sign up</h1>
        </Link>
      </div>
    </div>
  </div>
);

const SocialOptions = () => (
  <div>
    <div className="flex gap-3 mb-3">
      {/* Social login buttons can be added here */}
    </div>
  </div>
);

const Email = ({
  email,
  setEmail,
  password,
  setPassword,
  handleLogin,
  isLoading,
}) => {
  return (
    <form onSubmit={handleLogin} className="mt-6">
      <div className="mb-6">
        <label
          htmlFor="email-input"
          className="mb-2 block text-[#000000] text-base"
        >
          Email
        </label>
        <input
          id="email-input"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="your.email@provider.com"
          className="w-full px-3 py-3 transition-shadow bg-white border text-sm border-[#ADADAD] rounded-md b placeholder-[#ADADAD] ring-1 ring-transparent"
          required
        />
      </div>
      <div className="mb-3">
        <label
          htmlFor="password-input"
          className="block mb-2 text-[#000000] text-base"
        >
          Password
        </label>
        <input
          id="password-input"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="••••••••••••"
          className="w-full px-3 py-3 transition-shadow bg-white border text-sm border-[#ADADAD] rounded-md b placeholder-[#ADADAD] ring-1 ring-transparent"
          required
        />
      </div>
      <SplashButton type="submit" className="w-full" disabled={isLoading}>
        {isLoading ? <Loader /> : "Sign in"}
      </SplashButton>
    </form>
  );
};

const Terms = () => (
  <p className="text-xs mt-9 text-zinc-400">
    By signing in, you agree to our{" "}
    <a target="_blank" href="/termsandconditions" className="text-[#EB5757]">
      Terms & Conditions
    </a>{" "}
    and{" "}
    <a target="_blank" href="/refundPolicy" className="text-[#EB5757]">
      Refund Policy.
    </a>
  </p>
);

const SplashButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={twMerge(
        "rounded-md bg-gradient-to-br from-[#EB5757] to-[#EB5757] px-4 py-2 text-lg text-zinc-50 transition-all hover:scale-[1.02] hover:ring-transparent active:scale-[0.98] mt-10",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

const Loader = () => (
  <div className="flex items-center justify-center">
    <div className="w-6 h-6 border-b-2 border-white rounded-full animate-spin"></div>
  </div>
);

export default LoginPage;
