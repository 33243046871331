import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    email: "",
    password: "",
    mentorsList: [],
    contactNumber: "",
    totalCreditsAvailable: 0,
    education: [],
    work: [],
    _id: "",
    id: "",
    createdAt: "",
    updatedAt: "",
    firstName: "",
    lastName: "",
    profile: "",
    userType: "",
  },
  token: "",
  email: "",
  password: "",
  mentorsList: [],
  _id: "",
  createdAt: "",
  updatedAt: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    addEducation: (state, action) => {
      state.user.education.push(action.payload);
    },
    addWorkExperience: (state, action) => {
      state.user.workExperience.push(action.payload);
    },
    deleteEducation: (state, action) => {
      state.user.education = state.user.education.filter(
        (edu) => edu.name !== action.payload
      );
    },
    deleteWorkExperience:  (state, action) => {
      state.user.work = state.user.work.filter(
        (exp) => exp.name !== action.payload
      );
    },
    setTotalCreditsAvailable: (state, action) => {
      state.user.totalCreditsAvailable = action.payload;
    },
  },
});

export const {
  setUser,
  addEducation,
  addWorkExperience,
  deleteEducation,
  deleteWorkExperience,
  setToken,
  setTotalCreditsAvailable,
} = userSlice.actions;

export default userSlice.reducer;

