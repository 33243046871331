import React, { useEffect, useState } from "react";
import ProfilePage from "../HomePage/ProfilePage/ProfilePage";
import Layout from "../HomePage/Layout";
import { makeApiCall } from "../../api/config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { urls } from "../../api/apiUrl";
import { formatDate, isUserMentor } from "../../utils/helper";
import { setUser } from "../../redux/features/user/userSlice";
import UpcomingSession from "../../components/UpcomingSession";
import PastSession from "../../components/PastSession";
import Loader from "../../components/Loader";
import ShowDetailModal from "../HomePage/ProfilePage/ShowDetailModal";

const Bookings = () => {
  let authData = JSON.parse(localStorage.getItem("tokens"));
  let token = useSelector((state) => state?.user?.token);

  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [pastSessions, setPastSessions] = useState([]);
  const [cancelledSessions, setCancelledSessions] = useState([]);
  const [showAllUpcoming, setShowAllUpcoming] = useState(false);
  const [showAllPast, setShowAllPast] = useState(false);
  const [showAllCancelled, setShowAllCancelled] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedForDetails, setSelectedForDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  let navigate = useNavigate();
  const isMentor = isUserMentor();

  useEffect(() => {
    if (token || authData?.access.token) {
      getUpcomingSession();
      getPastSession();
      getCancelledSession();
      getProfileData();
    } else {
      navigate("/login");
    }
  }, []);

  const getProfileData = async (token) => {
    await makeApiCall(
      "GET",
      isMentor ? urls.mentorMe : urls.userDetails,
      null,
      authData?.access?.token
    )
      .then((data) => {
        dispatch(setUser(data));
      })
      .catch((error) => {
        const { response } = error;
        // console.log(response);
      });
  };



  const getCancelledSession = async () => {
    setIsLoading(true);
    await makeApiCall(
      "GET",
      isMentor ? urls.mentorCanceledSessions : urls.canceledSessions,
      null,
      authData?.access?.token
    )
      .then((data) => {
        setCancelledSessions(data?.appointments);
      })
      .catch((error) => {
        const { response } = error;
        // console.log(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getUpcomingSession = async () => {
    setIsLoading(true);
    await makeApiCall(
      "GET",
      isMentor ? urls.mentorUpcomingSessions : urls.upcomingSessions,
      null,
      authData?.access?.token
    )
      .then((data) => {
        setUpcomingSessions(data?.appointments);
      })
      .catch((error) => {
        const { response } = error;
        // console.log(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPastSession = async () => {
    setIsLoading(true);
    await makeApiCall(
      "GET",
      isMentor ? urls.mentorCompletedSessions : urls.completedSessions,
      null,
      authData?.access?.token
    )
      .then((data) => {
        setPastSessions(data?.appointments);
      })
      .catch((error) => {
        const { response } = error;
        console.log(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleShowAll = (sessionType) => {
    switch (sessionType) {
      case "upcoming":
        setShowAllUpcoming(!showAllUpcoming);
        break;
      case "past":
        setShowAllPast(!showAllPast);
        break;
      case "cancelled":
        setShowAllCancelled(!showAllCancelled);
        break;
    }
  };

  const getDisplayedSessions = (sessions, showAll) => {
    return showAll ? sessions : sessions.slice(0, 3);
  };

  const renderName = (item) => {
    return !isMentor
      ? `${item?.consultantID?.firstName} ${item?.consultantID?.lastName}`
      : `${item?.userID?.firstName} ${item?.userID?.lastName}`;
  };

  const renderSessionTable = (sessions, showAll, sessionType) => (
    <div className="w-[75%]">
      <div className="flex flex-row items-center justify-between w-full pb-4">
        <p className="text-lg font-medium text-black">
          {sessionType === "upcoming"
            ? "Upcoming"
            : sessionType === "past"
            ? "Past"
            : "Cancelled"}{" "}
          Sessions
        </p>
        <p
          className="text-[#3366CC] underline font-normal cursor-pointer text-sm"
          onClick={() => toggleShowAll(sessionType)}
        >
          {showAll ? "Show Less" : "See All"}
        </p>
      </div>
      <div className="overflow-x-auto rounded-md shadow-md">
        <table className="min-w-full bg-white shadow-inner rounded-t-md">
          <thead>
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-[#3F3F3F] uppercase tracking-wider">
                Instructor Name & Date
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-[#3F3F3F] uppercase tracking-wider">
                Category
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-[#3F3F3F] uppercase tracking-wider">
                Concept
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-[#3F3F3F] uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="px-2 bg-white shadow-inner rounded-b-md ">
            {getDisplayedSessions(sessions, showAll).map((item, index) => (
              <tr key={index} className="py-2">
                <td className="px-4 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <img
                      className="w-10 h-10 rounded-full"
                      src={
                        item?.consultantID?.profile ||
                        item?.userID?.profile ||
                        !isMentor
                          ? "/assets/consultant.jpg"
                          : "https://img.freepik.com/premium-vector/man-professional-business-casual-young-avatar-icon-illustration_1277826-632.jpg"
                      }
                      alt=""
                    />
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                      {renderName(item)}
                      </div>
                      <div className="text-sm text-gray-500">
                        {formatDate(item.date)}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-0 py-4 whitespace-nowrap">
                  <span className="inline-flex text-xs leading-5 font-normal px-4 rounded-full bg-red-100 text-[#EB5757]">
                    {item.specialization || item?.consultantID?.specialization}
                  </span>
                </td>
                <td className="px-0 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {item.question}
                </td>
                <td className="px-0 py-4 text-sm font-medium whitespace-nowrap">
                  <button
                    onClick={() => {
                      setSelectedForDetails(item);
                      setOpenDetailsModal(true);
                    }}
                    className="bg-[#3366CC33] uppercase rounded-full font-normal py-1 px-4 text-xs text-[#3366CC]"
                  >
                    Show Details
                  </button>
                </td>
              </tr>
            ))}
            {sessions.length <= 0 && (
              <tr>
                <td colSpan="4" className="px-4 py-4 text-xs text-center">
                  No Data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <Layout>
      <div className="flex justify-between w-full h-screen overflow-hidden">
        <div className="flex-col hidden w-full h-full lg:flex">
          <p className="px-6 pb-3 text-xl font-medium text-black">Bookings</p>
          {isLoading ? (
            <div className="w-[75%]">
              <Loader />
            </div>
          ) : (
            <div className="flex flex-col flex-grow px-6 overflow-y-auto gap-y-8 hidescrollbar">
              <div className="w-[75%]">
                <UpcomingSession upcomingSessions={upcomingSessions} />
              </div>
              <div className="w-[75%]">
                <PastSession pastSessions={pastSessions} />
              </div>
              {renderSessionTable(
                cancelledSessions,
                showAllCancelled,
                "cancelled"
              )}
            </div>
          )}
        </div>
        <ProfilePage />
      </div>
      <ShowDetailModal
        isOpen={openDetailsModal}
        setIsOpen={setOpenDetailsModal}
        selectedForDetails={selectedForDetails}
      />
    </Layout>
  );
};

export default Bookings;
