import React, { useEffect, useState } from "react";
import {
  CallControls,
  CallingState,
  SpeakerLayout,
  StreamCall,
  StreamTheme,
  StreamVideo,
  StreamVideoClient,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";

import "@stream-io/video-react-sdk/dist/css/styles.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import tokenProvider from "../../provider/tokenProvider";
import { isUserMentor } from "../../utils/helper";
import Loader from "../../components/Loader";
import { urls } from "../../api/apiUrl";
import { makeApiCall } from "../../api/config";
import { toast } from "react-toastify";

export default function MenteeMeetingPage() {
  const { id: callId } = useParams();
  const { user: user1 } = useSelector((state) => state.user);
  let authData = JSON.parse(localStorage.getItem("tokens"));
  const [client, setClient] = useState(null);
  const [call, setCall] = useState(null);
  const [isJoining, setIsJoining] = useState(false);
  const API_KEY = "hwaqj7vu675a";

  useEffect(() => {
    const initializeCall = async () => {
      if (user1 && authData?.access?.token && !client && !isJoining) {
        setIsJoining(true);

        try {
          const token = await tokenProvider(authData?.access?.token);

          const videoClient = new StreamVideoClient({
            apiKey: API_KEY,
            user: {
              id: user1.id,
              name: user1.firstName || user1.id,
              image: user1.profile,
            },
            tokenProvider: () => token,
          });

          await videoClient.connectUser({
            id: user1.id,
            name: user1.firstName || user1.id,
            image: user1.profile,
          });

          const newCall = videoClient.call("default", callId);
          await newCall.join({ create: true });

          setCall(newCall);
          setClient(videoClient);
        } catch (err) {
          console.error("Failed to connect user or join call:", err);
        } finally {
          setIsJoining(false);
        }
      }
    };

    initializeCall();
  }, [user1, callId]);

  useEffect(() => {
    return () => {
      if (client) {
        client.disconnectUser().catch((err) => {
          console.error("Failed to disconnect user:", err);
        });
      }
    };
  }, [client]);

  if (!client || !call) {
    return <Loader />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-6xl mx-auto ">
        <StreamVideo client={client}>
          <StreamCall call={call}>
            <MyUILayout callId={callId} />
          </StreamCall>
        </StreamVideo>
      </div>
    </div>
  );
}

export const MyUILayout = ({ callId }) => {
  let authData = JSON.parse(localStorage.getItem("tokens"));
  const { useCallCallingState } = useCallStateHooks();
  const callingState = useCallCallingState();
  const navigate = useNavigate();
  const isMentor = isUserMentor();
  const [slotMins, setSlotMins] = useState("");
  const [slotTime, setSlotTime] = useState('');
  const [isEqual, setIsEqual] = useState(false)

  const updateStatusToCompleted = async () => {
    await makeApiCall(
      "PUT",
      urls.updateAppointmentCompleted(callId),
      null,
      authData?.access?.token
    )
      .then((data) => {
        navigate("/profile");
      })
      .catch((error) => {
        const { response } = error;
        // console.log(response);
      });
  };

  const getAppointmentByRoomId = async () => {
    await makeApiCall(
      "GET",
      urls.getAppointment(callId),
      null,
      authData?.access?.token
    )
      .then((data) => {
        setSlotMins(data?.minutes);
        setSlotTime(data?.slot);
      })
      .catch((error) => {
        const { response } = error;
      })
      .finally(() => {});
  };

  useEffect(() => {
    getAppointmentByRoomId();
  }, [callId]);

  useEffect(() => {
    if (callingState !== CallingState.JOINED) {
      if (isMentor) {
        updateStatusToCompleted();
      }
      toast.error(isMentor ? "Call ended!" : "You left the call!");
      navigate("/profile");
    }
  }, [callingState, navigate]);

  const parseTime = (time) => {
    const [hour, minutePart = ""] = time.split(":");
    const [minutes, period = ""] = minutePart.split(" ");
    let hours = parseInt(hour);
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }
    return { hours, minutes: parseInt(minutes) };
  };

  useEffect(() => {
    let intervalId;

    const checkSlotTime = () => {
      const isTimeReached = isCurrentTimeEqualToOrGreaterThanSlotTime();
      if (isTimeReached) {
        setIsEqual(true); // Update the state
      }
    };

    const isCurrentTimeEqualToOrGreaterThanSlotTime = () => {
      const now = new Date();
      const parsedSlotTime = parseTime(slotTime);
      const currentHour = now.getHours();
      const currentMinutes = now.getMinutes();

      return (
        currentHour > parsedSlotTime.hours ||
        (currentHour === parsedSlotTime.hours &&
          currentMinutes >= parsedSlotTime.minutes)
      );
    };

    intervalId = setInterval(checkSlotTime, 30000);
    checkSlotTime(); 

    if (slotMins && slotMins > 0 && isEqual) {
      const slotDurationMs = slotMins * 60 * 1000;
      const fiveMinutesBefore = slotDurationMs - 5 * 60 * 1000;

      const toastTimeout = setTimeout(() => {
        toast.error("The call will end in 5 minutes.");
      }, fiveMinutesBefore);

      const endCallTimeout = setTimeout(() => {
        updateStatusToCompleted();
        toast.error("The call time is over. Call ended!");
        navigate("/profile");
      }, slotDurationMs);

      return () => {
        clearTimeout(toastTimeout);
        clearTimeout(endCallTimeout);
        clearInterval(intervalId);
      };
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [slotTime, slotMins, isEqual, updateStatusToCompleted, navigate]);

  if (callingState !== CallingState.JOINED) {
    return <Loader />;
  }

  return (
    <StreamTheme>
      <SpeakerLayout participantsBarPosition="bottom" />
      <CallControls />
    </StreamTheme>
  );
};
