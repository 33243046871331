// const baseUrl = "http://13.232.173.103/api";
const baseUrl = "https://api.askitall.co/v1";

export const urls = {
  mentorLogin: `${baseUrl}/consultant-auth/login`,
  mentorRefreshTokens: `${baseUrl}/consultant-auth/refresh-tokens`,

  mentorMe: `${baseUrl}/consultant/me`,
  mentorUpcomingSessions: `${baseUrl}/consultant/appointments/upcoming`,
  mentorCompletedSessions: `${baseUrl}/consultant/appointments/completed`,
  mentorCanceledSessions: `${baseUrl}/consultant/appointments/canceled`,
  mentorPendingSessions: `${baseUrl}/consultant/appointments/pending`,
  mentorMeetToken: `${baseUrl}/consultant/meeting/token`,
  updateAppointmentStatus: (appointmentId) => `${baseUrl}/consultant/appointments/${appointmentId}/status`,
  consultantSlots: `${baseUrl}/consultant/available/slots`,
  rescheduleSlots: `${baseUrl}/consultant/reschedule/slots`,
  scheduleSlots: `${baseUrl}/consultant/slots`,
  updateAppointmentCompleted: (roomID) => `${baseUrl}/consultant/appointment/completed/${roomID}`,
  getAppointment: (roomID) => `${baseUrl}/consultant/appointment/${roomID}`,



  login: `${baseUrl}/auth/login`,
  loginByGoogleAuth: (userId) => `${baseUrl}/auth/login/google/${userId}`,
  signUp: `${baseUrl}/users/signup`,
  refreshTokens: `${baseUrl}/auth/refresh-tokens`,

  resendOtp: `${baseUrl}/auth/resend-otp`,
  sendOtp: `${baseUrl}/auth/send-otp`,
  verifyOtp: `${baseUrl}/auth/verify-otp`,

  userDetails: `${baseUrl}/users/me`,
  updateDetails: `${baseUrl}/users/update`,
  mentorSignup: `${baseUrl}/mentors/signup`,
  getAllMentors: `${baseUrl}/mentors`,
  getSingleUser: `${baseUrl}/users`,
  booking: `${baseUrl}/booking`,

  upcomingSessions: `${baseUrl}/users/appointments/upcoming`,
  completedSessions: `${baseUrl}/users/appointments/completed`,
  canceledSessions: `${baseUrl}/users/appointments/canceled`,
  getConsultants: (specialization) =>
    `${baseUrl}/users/consultants?specialization=${specialization}`,
  getConsultantsById: (id) => `${baseUrl}/users/consultants/${id}`,
  getConsultantsDatesById: (id) => `${baseUrl}/users/consultants/${id}/dates`,
  getAvailableSlots: (consultantID, date) =>
    `${baseUrl}/users/availability/consultants?consultantID=${consultantID}&date=${date}`,
  book: `${baseUrl}/users/consultants/book`,
  getPaymentSessionid: (amount) =>
    `${baseUrl}/users/payment/session?amount=${amount}`,
  verifyPayment: `${baseUrl}/users/payment/verify`,
  meetToken: `${baseUrl}/users/meeting/token`,

  getPublicConsultants: (specialization) =>
    `${baseUrl}/users/public/consultants?specialization=${specialization}`,

};
