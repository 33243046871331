import React from "react";

const ProjectLoader = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="w-5 h-5 mx-1 rounded-full bubble bg-gradient-to-r from-pink-500 to-orange-500 animate-bubbleAnimation"></div>
      <div className="w-5 h-5 mx-1 rounded-full bubble bg-gradient-to-r from-pink-500 to-orange-500 animate-bubbleAnimation"></div>
      <div className="w-5 h-5 mx-1 rounded-full bubble bg-gradient-to-r from-pink-500 to-orange-500 animate-bubbleAnimation"></div>
    </div>
  );
};

export default ProjectLoader;
