import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { urls } from "../../../api/apiUrl";
import { makeApiCall } from "../../../api/config";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/features/user/userSlice";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Education name is required")
    .min(2, "Education name must be at least 2 characters")
    .max(100, "Education name must not exceed 100 characters"),
  degree: Yup.string()
    .required("Degree is required")
    .min(2, "Degree must be at least 2 characters")
    .max(50, "Degree must not exceed 50 characters"),
});

export default function EditEducationModal({ isOpen, setIsOpen }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  let authData = JSON.parse(localStorage.getItem("tokens"));
  let token = useSelector((state) => state?.user?.token);
  function closeModal() {
    setIsOpen(false);
  }

  const initialValues = {
    name: "",
    degree: "",
  };

  const updateProfileData = async (data) => {
    await makeApiCall(
      "PUT",
      `${urls.updateDetails}`,
      data,
      authData?.access?.token
    )
      .then((data) => {
        // console.log("dtaaaa", data);
        dispatch(setUser(data));
        setIsOpen(false);
      })
      .catch((error) => {
        const { response } = error;
        // console.log(response);
      });
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const data = {
      education: [
        ...user.education,
        {
          ...values,
        },
      ],
    };
    if (token || authData?.access?.token) {
      await updateProfileData(data);
    }

    setSubmitting(false);
    resetForm();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Add Education
                </Dialog.Title>
                <div className="mt-4 ">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched, isSubmitting }) => (
                      <Form className="space-y-4">
                        <div>
                          <label
                            htmlFor="educationName"
                            className="block mb-1 text-sm font-medium text-gray-700"
                          >
                            Education Name
                          </label>
                          <Field
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Enter education name"
                            className={`w-full px-3 py-2 placeholder-gray-400 border rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 ${
                              errors.name && touched.name
                                ? "border-red-500"
                                : "border-gray-300"
                            }`}
                          />
                          <ErrorMessage name="name">
                            {(msg) => (
                              <div className="mt-1 text-sm text-red-500">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div>
                          <label
                            htmlFor="degree"
                            className="block mb-1 text-sm font-medium text-gray-700"
                          >
                            Degree
                          </label>
                          <Field
                            id="degree"
                            name="degree"
                            type="text"
                            placeholder="Enter degree"
                            className={`w-full px-3 py-2 placeholder-gray-400 border rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 ${
                              errors.degree && touched.degree
                                ? "border-red-500"
                                : "border-gray-300"
                            }`}
                          />
                          <ErrorMessage name="degree">
                            {(msg) => (
                              <div className="mt-1 text-sm text-red-500">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#EB5757] hover:bg-[#DE5454] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                            isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                        >
                          {isSubmitting ? "Saving..." : "Save"}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
