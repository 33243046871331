import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditEducationModal from "./EditEducationModal";
import { PencilSquareIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import EditExperienceModal from "./EditExpereinceModal";
import EditProfileDetails from "./EditProfileDetails";
import DeleteEducationDialog from "./DeleteEducationDialog";
import DeleteExperienceDialog from "./DeleteExperienceDialog";
import { isUserMentor } from "../../../utils/helper";

const ProfilePage = () => {
  const { user } = useSelector((state) => state.user);
  const [openEducationDetailsDialog, setOpenEducationDetailsDialog] =
    useState(false);
  const [openExpereinceDialog, setOpenExpereinceDialog] = useState(false);
  const [editProfileDetailsDialog, setEditProfileDetailsDialog] =
    useState(false);
  const location = window.location.pathname;

  const [selectedSchoolForDelete, setSelectedSchoolForDelete] = useState("");
  const [deleteEducationDialog, setDeleteEducationDialog] = useState(false);
  const [selectedExperienceForDelete, setSelectedExperienceForDelete] =
    useState("");
  const [deleteExperienceDialog, setDeleteExperienceDialog] = useState(false);

  const onEducationDelete = (school) => {
    setSelectedSchoolForDelete(school);
    setDeleteEducationDialog(true);
  };

  const onExperienceDelete = (company) => {
    setSelectedExperienceForDelete(company);
    setDeleteExperienceDialog(true);
  };

  const isMentor = isUserMentor();

  return (
    <div className="fixed right-0 w-[20%] h-full bg-white rounded-md shadow-lg hideScrollbar lg:overflow-hidden">
      <div className="p-4">
        <div
          className={`flex items-center w-full justify-between text-black ${
            location === "/bookings" ? "lg:flex hidden" : ""
          } `}
        >
          <p className="text-xl font-semibold ">Your Profile</p>
          <PencilSquareIcon
            onClick={() => setEditProfileDetailsDialog(true)}
            className="text-[#9E9E9E] size-5 cursor-pointer"
          />
        </div>

        <div className={`  flex flex-row lg:flex-col items-center my-3`}>
          <div className="w-24 h-24 mb-2 overflow-hidden border-4 border-red-500 rounded-full lg:w-20 lg:h-20">
            <img
              src={
                user?.profile
                  ? user.profile
                  : isMentor
                  ? "/assets/consultant.jpg"
                  : "https://img.freepik.com/premium-vector/man-professional-business-casual-young-avatar-icon-illustration_1277826-632.jpg"
              }
              alt="Profile"
              className="object-cover w-full h-full p-2 rounded-full"
            />
          </div>
          <div className="flex flex-col items-start ml-4 lg:items-center">
            <p className="text-sm font-semibold text-[#EB5757] ">
              {user.firstName} {user?.lastName}
            </p>
            <h2 className="text-lg font-semibold lg:text-sm">{user?.email}</h2>
            <p className="text-sm font-semibold text-[#EB5757] ">
              {user?.totalCreditsAvailable} Credits
            </p>
            <p className="text-xs font-medium text-[#7E7E7E]">
              {isMentor ? "Mentor" : "Mentee"}
            </p>
          </div>
        </div>
        <div className={`  flex flex-col justify-between py-2`}>
          <div
            className={`${
              location === "/bookings" ? "lg:block hidden" : ""
            } mb-8`}
          >
            <div className="flex items-center justify-between w-full mb-4">
              <p className="text-sm font-semibold ">Education</p>
              <PlusCircleIcon
                onClick={() => setOpenEducationDetailsDialog(true)}
                className="text-[#9E9E9E] size-6 cursor-pointer"
              />
            </div>
            <div className="space-y-4 lg:space-y-2">
              {user?.education?.map((item, index) => {
                const isLast = index === user.education.length - 1;
                return (
                  <div key={index}>
                    <EducationItem
                      school={item.name}
                      degree={item.degree}
                      handleDelete={onEducationDelete}
                    />
                    {!isLast && (
                      <hr className="border-[0.5px] mt-1 border-[#D8D8D8] w-full" />
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={``}>
            <div
              className={`${
                location === "/bookings" ? "lg:block hidden" : ""
              } mb-4`}
            >
              <div className="flex items-center justify-between w-full mb-0">
                <p className="text-sm font-semibold ">Work Experience</p>
                <PlusCircleIcon
                  onClick={() => setOpenExpereinceDialog(true)}
                  className="text-[#9E9E9E] size-6 cursor-pointer"
                />
              </div>
            </div>
            <div
              className={` ${
                location === "/bookings" ? "lg:block hidden" : ""
              } space-y-4 lg:space-y-2`}
            >
              {user?.work?.map((item, index) => {
                const isLast = index === user.work.length - 1;
                return (
                  <div key={index}>
                    <WorkExperienceItem
                      company={item.name}
                      position={item.role}
                      startDate={item.startDate}
                      endDate={item.endDate}
                      handleDelete={onExperienceDelete}
                    />
                    {!isLast && (
                      <hr className="border-[0.5px] mt-1 border-[#D8D8D8] w-full" />
                    )}
                  </div>
                );
              })}
            </div>

            <div className="flex flex-col justify-start pt-6 lg:hidden gap-y-4">
              <p className="text-sm font-semibold ">Upcoming Sessions</p>
              <div className="flex flex-row items-start justify-start gap-x-4">
                <div className="flex flex-col justify-start items-start gap-y-2 p-3 bg-[#F6F5FB] rounded-xl">
                  <p className="w-full max-w-xs text-sm font-medium text-black">
                    Prashant Kumar <br /> Singh
                  </p>
                  <p className="text-sm font-normal text-black">
                    Understanding Concept of React
                  </p>
                </div>

                <div className="flex flex-col justify-start items-start gap-y-2 p-3 bg-[#F5F9F9] rounded-xl">
                  <p className="w-full max-w-xs text-sm font-medium text-black">
                    Prashant Kumar <br /> Singh
                  </p>
                  <p className="text-sm font-normal text-black">
                    Understanding Concept of React
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-start pt-6 lg:hidden gap-y-4">
              <p className="text-sm font-semibold ">Past Sessions</p>
              <div className="flex flex-row items-start justify-start gap-x-4">
                <div className="flex flex-col justify-start items-start gap-y-2 p-3 bg-[#F6F5FB] rounded-xl">
                  <p className="w-full max-w-xs text-sm font-medium text-black">
                    Prashant Kumar <br /> Singh
                  </p>
                  <p className="text-sm font-normal text-black">
                    Understanding Concept of React
                  </p>
                </div>

                <div className="flex flex-col justify-start items-start gap-y-2 p-3 bg-[#F5F9F9] rounded-xl">
                  <p className="w-full max-w-xs text-sm font-medium text-black">
                    Prashant Kumar <br /> Singh
                  </p>
                  <p className="text-sm font-normal text-black">
                    Understanding Concept of React
                  </p>
                </div>
              </div>
            </div>

            {location === "/bookings" && (
              <div className="flex flex-col justify-start pt-6 lg:hidden gap-y-4">
                <p className="text-sm font-semibold ">Cancelled Sessions</p>
                <div className="flex flex-row items-start justify-start gap-x-4">
                  <div className="flex flex-col justify-start items-start gap-y-2 p-3 bg-[#F6F5FB] rounded-xl">
                    <p className="w-full max-w-xs text-sm font-medium text-black">
                      Prashant Kumar <br /> Singh
                    </p>
                    <p className="text-sm font-normal text-black">
                      Understanding Concept of React
                    </p>
                  </div>

                  <div className="flex flex-col justify-start items-start gap-y-2 p-3 bg-[#F5F9F9] rounded-xl">
                    <p className="w-full max-w-xs text-sm font-medium text-black">
                      Prashant Kumar <br /> Singh
                    </p>
                    <p className="text-sm font-normal text-black">
                      Understanding Concept of React
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <EditEducationModal
        isOpen={openEducationDetailsDialog}
        setIsOpen={setOpenEducationDetailsDialog}
      />
      <EditExperienceModal
        isOpen={openExpereinceDialog}
        setIsOpen={setOpenExpereinceDialog}
      />
      <EditProfileDetails
        isOpen={editProfileDetailsDialog}
        setIsOpen={setEditProfileDetailsDialog}
      />
      <DeleteEducationDialog
        isOpen={deleteEducationDialog}
        setIsOpen={setDeleteEducationDialog}
        selectedSchoolForDelete={selectedSchoolForDelete}
      />
      <DeleteExperienceDialog
        isOpen={deleteExperienceDialog}
        setIsOpen={setDeleteExperienceDialog}
        selectedExperienceToDelete={selectedExperienceForDelete}
      />
    </div>
  );
};

const EducationItem = ({ school, degree, handleDelete }) => (
  <div className="flex items-center justify-between">
    <div>
      <p className="text-xs font-medium">{school}</p>
      <p className="text-xs text-gray-600">{degree}</p>
    </div>
    <span
      onClick={() => handleDelete(school)}
      className="bg-[#EB5757] text-white text-[10px] px-3 py-1 rounded-lg cursor-pointer"
    >
      Delete
    </span>
  </div>
);

const WorkExperienceItem = ({
  company,
  position,
  startDate,
  endDate,
  handleDelete,
}) => (
  <div className="flex items-center justify-between">
    <div className="space-y-1">
      <p className="text-xs font-medium">{company}</p>
      <p className="text-xs text-gray-600 ">{position}</p>
      <p className="text-[10px] text-gray-600 ">
        {startDate} to {endDate}
      </p>
    </div>
    <span
      onClick={() => handleDelete(company)}
      className="bg-[#EB5757] text-white text-[10px] px-3 py-1 rounded-lg cursor-pointer"
    >
      Delete
    </span>
  </div>
);

export default ProfilePage;
