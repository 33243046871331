import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import ProfilePage from "../ProfilePage/ProfilePage";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { urls } from "../../../api/apiUrl";
import { makeApiCall } from "../../../api/config";
import { formatDate, isUserMentor } from "../../../utils/helper";
import { setUser } from "../../../redux/features/user/userSlice";
import Loader from "../../../components/Loader";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import AcceptOrRejectMeetModal from "./AcceptOrRejectMeetModal";

const AcceptPage = () => {
  let authData = JSON.parse(localStorage.getItem("tokens"));
  let token = useSelector((state) => state?.user?.token);

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [acceptOrRejectModalOpen, setAcceptOrRejectModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("ACCEPTED");

  let navigate = useNavigate();

  useEffect(() => {
    if (token || authData?.access.token) {
      getPendingSessions();
    } else {
      navigate("/login");
    }
  }, []);

  const isMentor = isUserMentor();

  const getPendingSessions = async () => {
    setIsLoading(true);
    await makeApiCall(
      "GET",
      urls.mentorPendingSessions,
      null,
      authData?.access?.token
    )
      .then((data) => {
        setPendingAppointments(data?.appointments);
      })
      .catch((error) => {
        const { response } = error;
        // console.log(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Layout>
      <div className="flex justify-between w-[75%] h-screen overflow-hidden ">
        <div className="flex-col hidden w-full h-full lg:flex">
          <div className="flex flex-col flex-grow px-6 overflow-y-auto gap-y-6 hidescrollbar">
            <div className="">
              <div className="flex flex-row items-center justify-between w-full pb-4">
                <p className="text-lg font-medium text-black">Meets</p>
              </div>
              {isLoading ? (
                <Loader />
              ) : (
                <div className="overflow-x-auto rounded-lg shadow-md">
                  <table className="min-w-full bg-white ">
                    <thead>
                      <tr>
                        <th className="px-4 py-3 text-left text-xs font-medium text-[#3F3F3F] uppercase tracking-wider">
                          User Name & Date
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-[#3F3F3F] uppercase tracking-wider">
                          Slot/Time
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-[#3F3F3F] uppercase tracking-wider">
                          Question
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-[#3F3F3F] uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="px-2 bg-white ">
                      {pendingAppointments?.map((item) => (
                        <tr key={item?._id} className="py-2">
                          <td className="px-4 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <img
                                className="w-10 h-10 rounded-full"
                                src={
                                  item?.userID?.profile ||
                                  "https://img.freepik.com/premium-vector/man-professional-business-casual-young-avatar-icon-illustration_1277826-632.jpg"
                                }
                                alt=""
                              />
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {item?.userID?.firstName +
                                    " " +
                                    item?.userID?.lastName}
                                </div>
                                <div className="text-sm text-gray-500">
                                  {formatDate(item.date)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-0 py-4 whitespace-nowrap">
                            <span className="inline-flex text-xs leading-5 font-normal px-4 rounded-full bg-red-100 text-[#EB5757]">
                              {item?.slot} - {item?.minutes} mins
                            </span>
                          </td>
                          <td className="px-0 py-4 text-sm text-gray-500 whitespace-nowrap">
                            {item.question}
                          </td>
                          <td className="flex items-center gap-3 px-0 py-4">
                            <button
                              onClick={() => {
                                setSelectedStatus("ACCEPTED");
                                setSelectedAppointment(item?._id);
                                setAcceptOrRejectModalOpen(true);
                              }}
                              className="bg-[#3366CC33] uppercase rounded-md font-normal p-2 text-xs text-[#3366CC]"
                              title="Accept"
                            >
                              <CheckIcon className="w-4 h-4" />
                            </button>

                            <button
                              onClick={() => {
                                setSelectedStatus("REJECTED");
                                setSelectedAppointment(item?._id);
                                setAcceptOrRejectModalOpen(true);
                              }}
                              className="bg-[#cc333333] uppercase rounded-md font-normal p-2 text-xs text-red-600"
                              title="Reject"
                            >
                              <XMarkIcon className="w-4 h-4" />
                            </button>
                          </td>
                        </tr>
                      ))}
                      {pendingAppointments.length <= 0 && (
                        <tr>
                          <td colSpan="4" className="py-4 text-xs text-center">
                            No Data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
        <ProfilePage />
      </div>
      <AcceptOrRejectMeetModal
        isOpen={acceptOrRejectModalOpen}
        setIsOpen={setAcceptOrRejectModalOpen}
        status={selectedStatus}
        selectedMeetForAcceptOrReject={selectedAppointment}
        getPendingSessions={getPendingSessions}
      />
    </Layout>
  );
};

export default AcceptPage;
