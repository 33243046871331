import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { makeApiCall } from "../api/config";
import { isUserMentor } from "../utils/helper";
import { urls } from "../api/apiUrl";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/features/user/userSlice";
import ProjectLoader from "../components/ProjectLoader";
function isTokenExpired(expiryDate) {
  const expDate = new Date(expiryDate).getTime();
  const currentDate = Date.now();

  return currentDate >= expDate;
}

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const storedTokens = JSON.parse(localStorage.getItem("tokens"));
  const dispatch = useDispatch();
  const location = useLocation();

  const getProfileData = async (token) => {
    const isMentor = isUserMentor();
    await makeApiCall(
      "GET",
      isMentor ? urls.mentorMe : urls.userDetails,
      null,
      token
    )
      .then((data) => {
        dispatch(setUser(data));
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  };

  useEffect(() => {
    const checkToken = async () => {
      if (storedTokens) {
        const isMentor = isUserMentor();
        const tokenExpired = isTokenExpired(storedTokens.access.expires);

        if (tokenExpired) {
          try {
            const response = await makeApiCall(
              "POST",
              isMentor ? urls.mentorRefreshTokens : urls.refreshTokens,
              {
                refreshToken: storedTokens.refresh.token,
              }
            );

            localStorage.setItem("tokens", JSON.stringify(response));

            storedTokens.access.token = response.access.token;

            await getProfileData(response.access.token);
          } catch (error) {
            console.error("Error refreshing token:", error);
            setIsAuthenticated(false);
            return;
          }
        } else {
          await getProfileData(storedTokens.access.token);
        }

        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    checkToken();
  }, [storedTokens]);

  if (isAuthenticated === null) {
    return <ProjectLoader />;
  }

  if (
    isAuthenticated &&
    (location.pathname === "/login" || location.pathname === "/signup")
  ) {
    return <Navigate to="/dashboard" />;
  }

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
