import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Sidebar from "./Sidebar/Sidebar";
import { useNavigate } from "react-router";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex overflow-hidden bg-white lg:h-full hideScrollbar">
      {/* <AnimatePresence>
          <motion.div
            initial={{ x: -300 }}
            animate={{ x: 0 }}
            exit={{ x: -300 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="fixed z-20 lg:relative"
            > */}
      {(sidebarOpen || window.innerWidth >= 1024) && (
        <Sidebar closeSidebar={() => setSidebarOpen(false)} />
      )}
      {/* </motion.div>
      </AnimatePresence> */}
      <div className="flex flex-col flex-1 overflow-hidden">
        <header className="bg-white border border-[#DBDBDB] shadow-sm lg:hidden flex items-center w-full justify-between px-4">
          <div
            className="flex flex-row items-center flex-shrink-0 text-black cursor-pointer gap-x-2"
            onClick={() => navigate("/")}
          >
            <img className="w-5 h-5 text-black" src="/logo.svg" alt="Logo" />
            <p className="text-xl lg:text-2xl">Askitall!</p>
          </div>
          <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className="p-4 focus:outline-none focus:bg-gray-100"
          >
            {sidebarOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-x"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </header>
        <main className="flex-1 w-full px-0 py-2 overflow-x-hidden overflow-y-auto bg-white lg:px-4 lg:py-4 ">
          {children}
        </main>
      </div>
      {sidebarOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 z-10 bg-black bg-opacity-50 lg:hidden"
          onClick={() => setSidebarOpen(false)}
        />
      )}
    </div>
  );
};

export default Layout;
