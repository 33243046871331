import React, { useEffect, useState } from "react";
import Layout from "../HomePage/Layout";
import { Link, useNavigate } from "react-router-dom";
import { makeApiCall } from "../../api/config";
import { urls } from "../../api/apiUrl";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { isUserMentor } from "../../utils/helper";

const Advisor = () => {
  const { user } = useSelector((state) => state.user);
  let authData = JSON.parse(localStorage.getItem("tokens"));
  let navigate = useNavigate();
  const [consultants, setConsultants] = useState([]);
  const [selected, setSelected] = useState("Finance");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authData?.access.token) {
      getAdvisor();
    } else {
      navigate("/login");
    }
  }, [selected]);

  const getAdvisor = async () => {
    setIsLoading(true);
    let url = urls.getConsultants(selected);

    await makeApiCall("GET", url, null, authData?.access?.token)
      .then((data) => {
        setConsultants(data);
      })
      .catch((error) => {
        const { response } = error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAdvisor();
  }, [selected]);

  const handleSelectChange = (event) => {
    setSelected(event.target.value);
  };

  const AdvisorCard = ({
    firstName,
    lastName,
    specialization,
    experience,
    minutesCompleted,
    candidatesAdvised,
    chargePerMinutes,
    id,
    profile,
  }) => {
    const isMentor = isUserMentor();
    return (
      <div className="min-w-[300px] p-5 mb-4 bg-white shadow-lg rounded-3xl">
        <div className="flex items-center mb-4 border-b border-b-[#EBEBEB] pb-4">
          <img
            className="mr-4 rounded-full w-28 h-2w-28"
            src={
              profile || !isMentor
                ? "/assets/consultant.jpg"
                : "https://img.freepik.com/premium-vector/man-professional-business-casual-young-avatar-icon-illustration_1277826-632.jpg"
            }
            alt="Profile"
          />
          <div>
            <h2 className="text-xl font-normal text-black">
              {firstName} {lastName}
            </h2>
            <p className="text-gray-600">{specialization}</p>
            <p className="text-sm text-gray-500">
              Experience - {experience} Years
            </p>
            <div className="flex text-yellow-400">{"★".repeat(5)}</div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <p className="text-[#5F5F5F] text-sm">
              {minutesCompleted} mins completed
            </p>
            <p className="text-[#5F5F5F] text-sm">
              Advised {candidatesAdvised} candidates
            </p>
            <p className="text-sm font-medium text-black">
              ₹{chargePerMinutes}/- per minute
            </p>
          </div>
          <Link
            to={`/advisors/${id}`}
            className="px-4 py-2 text-white transition duration-300 bg-red-500 rounded-md cursor-pointer hover:bg-red-600"
          >
            Connect
          </Link>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <div className="flex flex-col items-start justify-start px-5 py-5 gap-y-3">
        <div className="flex flex-col items-center justify-between w-full mb-4 lg:flex-row">
          <div className="w-full mb-4 lg:w-3/5 lg:mb-0">
            <form className="flex items-center gap-4">
              <div className="relative min-w-[70%]">
                <input
                  type="search"
                  className="w-full p-4 py-2 pr-10 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg"
                  placeholder="Search a mentor here...."
                  required
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.66668 14C11.1645 14 14 11.1645 14 7.66671C14 4.1689 11.1645 1.33337 7.66668 1.33337C4.16887 1.33337 1.33334 4.1689 1.33334 7.66671C1.33334 11.1645 4.16887 14 7.66668 14Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.6667 14.6667L13.3333 13.3334"
                      stroke="#9E9E9E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <select
                onChange={handleSelectChange}
                className="p-2 pr-8 bg-white border rounded-md min-w-[20%]"
              >
                {/* <option value="Filter">Filter</option> */}
                <option value="Finance">Finance</option>
                <option value="Law">Law</option>
              </select>
            </form>
          </div>
          <div className="flex items-center space-x-4">
            <p>Balance Credits : ₹{user?.totalCreditsAvailable} </p>
            <Link
              to="/credits"
              className="px-4 py-2 text-white bg-red-500 rounded-md cursor-pointer"
            >
              Add Credits
            </Link>
          </div>
        </div>

        <p className="text-xl font-medium text-black">{selected}</p>

        {isLoading ? (
          <Loader />
        ) : (
          <div className="grid justify-center w-full grid-cols-1 px-2 place-content-center gap-x-6 gap-y-1 sm:grid-cols-2 max-w-7xl lg:grid-cols-3">
            {consultants?.length > 0 ? (
              consultants.map((advisor, index) => (
                <AdvisorCard key={index} {...advisor} />
              ))
            ) : (
              <p>There is no advisor available!!</p>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Advisor;
