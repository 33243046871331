import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { HOMEPAGE } from "../../utils/constants";
import Coin from "../../assets/images/coins.png";
import Layout from "../HomePage/Layout";
import { useSelector, useDispatch } from "react-redux";
import { makeApiCall } from "../../api/config";
import { urls } from "../../api/apiUrl";
import { load } from "@cashfreepayments/cashfree-js";
import { setUser } from "../../redux/features/user/userSlice";

function AddCredit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [amount, setAmount] = useState(-1);
  const { user } = useSelector((state) => state.user);
  const [message, setMessage] = useState(null);
  let authData = JSON.parse(localStorage.getItem("tokens"));

  const addAmount = (amount) => {
    setAmount(amount);
  };

  let cashfree;
  let initializeSDK = async function () {
    cashfree = await load({
      mode: "production",
    });
  };

  initializeSDK();

  const getSessionid = async () => {
    const response = await makeApiCall(
      "GET",
      `${urls.getPaymentSessionid(amount)}`,
      null,
      authData?.access?.token
    );
    if (response?.payment_session_id && response?.order_id) {
      localStorage.setItem("orderId", response?.order_id);
      return response?.payment_session_id;
    }
  };

  const verifyUserPayment = async () => {
    const response = await makeApiCall(
      "POST",
      `${urls.verifyPayment}`,
      { orderId: localStorage.getItem("orderId") },
      authData?.access?.token
    );
    if (response) {
      dispatch(setUser(response));
      localStorage.removeItem("orderId");
    }
  };

  const addCredit = async () => {
    if (amount > 0) {
      try {
        let sessionId = await getSessionid();

        let checkoutOptions = {
          paymentSessionId: sessionId,
          redirectTarget: "_modal",
        };
        cashfree
          .checkout(checkoutOptions)
          .then(function (result) {
            if (result.error) {
              console.error("Error during checkout:", result.error.message);
            } else {
              setTimeout(verifyUserPayment, 1000);
            }
          })
          .catch((error) => {
            console.error("Error during checkout:", error);
          });
      } catch (error) {
        console.error("Error while getting session id:", error);
      }
    }
  };

  return (
    <Layout>
      <div className="p-4 md:p-4">
        <div className="w-full ">
          {message && (
            <div
              className="relative px-4 py-3 mb-4 text-green-700 bg-green-100 border border-green-400 rounded"
              role="alert"
            >
              <span className="block sm:inline">{message}</span>
            </div>
          )}
          <div className="mb-8">
            <h1 className="mb-2 text-2xl font-medium  text-[#333333]">
              Add Credits
            </h1>
            <h2 className="text-lg  text-[#333333]">
              Available Balance ₹ {user?.totalCreditsAvailable}
            </h2>
          </div>
          <div className="flex items-center justify-center gap-x-28 ">
            <div className="grid grid-cols-2 gap-8 mb-8 gap-x-16 md:grid-cols-3 ">
              {[100, 200, 300, 400, 500, 600].map((value) => (
                <button
                  key={value}
                  onClick={() => addAmount(value)}
                  className="bg-[#EB5757] text-white py-2 px-6 rounded"
                >
                  ₹ {value}
                </button>
              ))}
            </div>
            <div className="flex flex-col justify-center" >
            <img
                src="/assets/login.png"
                className="w-[290px] h-[290px] object-contain"
                alt="vector"
              />
              <div className="p-4 rounded-lg">
                <div className="flex flex-col items-center mb-4">
                  <input
                    type="number"
                    placeholder="Enter Amount"
                    className="w-full p-2 bg-transparent border-b  focus:outline-none  focus:ring-0 border-b-[#B8BBD2] focus:border-b-[#EB5757] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none "
                    value={amount === -1 ? "" : amount}
                    onChange={(e) => setAmount(Number(e.target.value))}
                  />
                </div>
                <div className="flex flex-row justify-center w-full mt-10 space-x-4">
                  <button
                    className="px-4 py-2  bg-transparent text-[#EB5757] rounded hover:bg-gray-400"
                    onClick={() => setAmount(-1)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#EB5757] text-white py-2 px-6 rounded-md"
                    onClick={addCredit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddCredit;
