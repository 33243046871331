import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { urls } from "../../../api/apiUrl";
import { makeApiCall } from "../../../api/config";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/features/user/userSlice";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Company name is required")
    .min(2, "Company name must be at least 2 characters")
    .max(100, "Company name must not exceed 100 characters"),
  
  role: Yup.string()
    .required("Degree is required")
    .min(2, "Degree must be at least 2 characters")
    .max(50, "Degree must not exceed 50 characters"),
  
  startDate: Yup.string()
    .required("Start date is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Start date must be in the format YYYY-MM-DD"),
  
  endDate: Yup.string()
    .required("End date is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "End date must be in the format YYYY-MM-DD")
});
export default function EditExperienceModal({ isOpen, setIsOpen }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  let authData = JSON.parse(localStorage.getItem("tokens"));
  let token = useSelector((state) => state?.user?.token);
  function closeModal() {
    setIsOpen(false);
  }

  const initialValues = {
    name: "",
    role: "",
    startDate: "",
    endDate: "",
  };

  const updateProfileData = async (data) => {
    await makeApiCall(
      "PUT",
      `${urls.updateDetails}`,
      data,
      authData?.access?.token
    )
      .then((data) => {
        // console.log("dtaaaa", data);
        dispatch(setUser(data));
        setIsOpen(false);
      })
      .catch((error) => {
        const { response } = error;
        // console.log(response);
      });
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const data = {
      work: [
        ...user.work,
        {
          ...values,
        },
      ],
    };
    if (token || authData?.access?.token) {
      await updateProfileData(data);
    }

    setSubmitting(false);
    resetForm();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Add Experience
                </Dialog.Title>
                <div className="mt-4 ">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched, isSubmitting }) => (
                      <Form className="space-y-4">
                        <div>
                          <label
                            htmlFor="educationName"
                            className="block mb-1 text-sm font-medium text-gray-700"
                          >
                             Name
                          </label>
                          <Field
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Enter company name"
                            className={`w-full px-3 py-2 placeholder-gray-400 border rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 ${
                              errors.name && touched.name
                                ? "border-red-500"
                                : "border-gray-300"
                            }`}
                          />
                          <ErrorMessage name="name">
                            {(msg) => (
                              <div className="mt-1 text-sm text-red-500">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div>
                          <label
                            htmlFor="role"
                            className="block mb-1 text-sm font-medium text-gray-700"
                          >
                            Role
                          </label>
                          <Field
                            id="role"
                            name="role"
                            type="text"
                            placeholder="Enter role"
                            className={`w-full px-3 py-2 placeholder-gray-400 border rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 ${
                              errors.role && touched.role
                                ? "border-red-500"
                                : "border-gray-300"
                            }`}
                          />
                          <ErrorMessage name="role">
                            {(msg) => (
                              <div className="mt-1 text-sm text-red-500">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div>
                          <label
                            htmlFor="startDate"
                            className="block mb-1 text-sm font-medium text-gray-700"
                          >
                            Start Date
                          </label>
                          <Field
                            id="startDate"
                            name="startDate"
                            type="date"
                            placeholder="Enter start date"
                            className={`w-full px-3 py-2 placeholder-gray-400 border rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 ${
                              errors.startDate && touched.startDate
                                ? "border-red-500"
                                : "border-gray-300"
                            }`}
                          />
                          <ErrorMessage name="startDate">
                            {(msg) => (
                              <div className="mt-1 text-sm text-red-500">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div>
                          <label
                            htmlFor="endDate"
                            className="block mb-1 text-sm font-medium text-gray-700"
                          >
                            End Date
                          </label>
                          <Field
                            id="endDate"
                            name="endDate"
                            type="date"
                            placeholder="Enter end date"
                            className={`w-full px-3 py-2 placeholder-gray-400 border rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 ${
                              errors.endDate && touched.endDate
                                ? "border-red-500"
                                : "border-gray-300"
                            }`}
                          />
                          <ErrorMessage name="endDate">
                            {(msg) => (
                              <div className="mt-1 text-sm text-red-500">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>

                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#EB5757] hover:bg-[#DE5454] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                            isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                        >
                          {isSubmitting ? "Saving..." : "Save"}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
