import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import ProfilePage from "../ProfilePage/ProfilePage";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { urls } from "../../../api/apiUrl";
import { makeApiCall } from "../../../api/config";
import { formatDate, isUserMentor } from "../../../utils/helper";
import { setUser } from "../../../redux/features/user/userSlice";
import UpcomingSession from "../../../components/UpcomingSession";
import PastSession from "../../../components/PastSession";
import Loader from "../../../components/Loader";

const Profile = () => {
  let authData = JSON.parse(localStorage.getItem("tokens"));
  let token = useSelector((state) => state?.user?.token);

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [pastSessions, setPastSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    if (token || authData?.access.token) {
      getUpcomingSession();
      getPastSession();
    } else {
      navigate("/login");
    }
  }, []);

  const isMentor = isUserMentor();

  const getUpcomingSession = async () => {
    setIsLoading(true);
    await makeApiCall(
      "GET",
      isMentor ? urls.mentorUpcomingSessions : urls.upcomingSessions,
      null,
      authData?.access?.token
    )
      .then((data) => {
        setUpcomingSessions(data?.appointments);
      })
      .catch((error) => {
        const { response } = error;
        // console.log(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPastSession = async () => {
    setIsLoading(true);
    await makeApiCall(
      "GET",
      isMentor ? urls.mentorCompletedSessions : urls.completedSessions,
      null,
      authData?.access?.token
    )
      .then((data) => {
        setPastSessions(data?.appointments);
      })
      .catch((error) => {
        const { response } = error;
        // console.log(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Layout>
      <div className="flex justify-between w-[75%] h-screen overflow-hidden ">
        <div className="flex-col hidden w-full h-full lg:flex">
          <div className="relative flex-shrink-0 w-full p-6 bg-white ">
            <div className="flex items-center mb-6 bg-white gap-x-6 ">
              <form className="w-full mx-auto">
                <label
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3">
                    <svg
                      className="w-4 h-4"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66668 14C11.1645 14 14 11.1645 14 7.66671C14 4.1689 11.1645 1.33337 7.66668 1.33337C4.16887 1.33337 1.33334 4.1689 1.33334 7.66671C1.33334 11.1645 4.16887 14 7.66668 14Z"
                        stroke="#9E9E9E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.6667 14.6667L13.3333 13.3334"
                        stroke="#9E9E9E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full p-4 ps-10 text-sm text-gray-900 border border-[#CCCCCCCC] rounded-lg bg-white dark:placeholder-[#9E9E9E] placeholder:font-medium dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search a mentor here...."
                    required
                  />
                </div>
              </form>
            </div>

            {!isMentor && (
              <div className="bg-[#EB5757] p-0 rounded-xl flex justify-between w-full overflow-hidden mb-6">
                <div className="flex flex-col justify-start p-5 gap-y-1">
                  <p className="text-xs font-normal text-white uppercase">
                    Solve Doubts
                  </p>
                  <p className="text-2xl font-medium text-white">You have a</p>
                  <p className="text-2xl font-medium text-white">
                    {user?.totalCreditsAvailable} credits
                  </p>
                  <Link
                    to="/credits"
                    className="px-2 py-2 my-2 text-sm text-center text-white bg-black rounded-full"
                  >
                    Add Credits
                  </Link>
                </div>
                <div>
                  <img src="/stars.svg" alt="Stars" />
                </div>
              </div>
            )}
            {isMentor && (
              <p className="text-lg font-medium text-black ">
                Welcome, {user?.firstName}
              </p>
            )}
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-col flex-grow px-6 overflow-y-auto gap-y-6 hidescrollbar">
              <UpcomingSession upcomingSessions={upcomingSessions} />
              <PastSession pastSessions={pastSessions} />
            </div>
          )}
        </div>
        <ProfilePage />
      </div>
    </Layout>
  );
};

export default Profile;
