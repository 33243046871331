export function formatDate(dateString) {
  const dateObj = new Date(dateString);
  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const year = dateObj.getFullYear();

  return `${day}/${month}/${year}`;
}

export function isUserMentor() {
  const data = localStorage.getItem("user");
  const user = JSON.parse(data);
  return user?.userType === "MENTOR";
}

export function isLoggedIn() {
  const storedTokens = JSON.parse(localStorage.getItem("tokens"));

  if (!storedTokens || !storedTokens.access || !storedTokens.access.expires) {
    return false;
  }

  const expiryDate = new Date(storedTokens.access.expires).getTime();
  const currentDate = new Date().getTime();



  return currentDate < expiryDate;
}
