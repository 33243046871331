import React, { useState } from "react";
import { useNavigate } from "react-router";
import { isLoggedIn } from "../../utils/helper";
// import { Link } from 'react-router-dom';

const NavbarWebsite1 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isLogin = isLoggedIn();

  return (
    <nav className="bg-white">
      <div className="px-4 py-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div
            className="flex flex-row items-center flex-shrink-0 text-black cursor-pointer gap-x-2"
            onClick={() => navigate("/")}
          >
            <img className="w-full " src="/logo.svg" alt="Logo" />
            {/* <p className='text-xl lg:text-2xl'>Askitall!</p> */}
          </div>
          <div className="items-center justify-center flex-grow hidden md:flex">
            <div className="flex items-baseline space-x-4">
              <a
                href="#"
                className="px-3 py-2 text-sm font-medium text-black rounded-md"
              >
                Home
              </a>
              {/* <a href="#about" className="px-3 py-2 text-sm font-medium text-black rounded-md">About</a> */}
              <a
                href="#services"
                className="px-3 py-2 text-sm font-medium text-black rounded-md"
              >
                Services
              </a>
              <a
                href="#faq"
                className="px-3 py-2 text-sm font-medium text-black rounded-md cursor-pointer"
              >
                FAQ
              </a>
              <a
                href="/contact"
                className="px-3 py-2 text-sm font-medium text-black rounded-md cursor-pointer"
              >
                Contact
              </a>
            </div>
          </div>
          <div className="hidden md:block">
            <button
              className="bg-[#EB5757] hover:bg-[#EB5757] text-white px-4 py-2 rounded-md text-sm font-medium"
              onClick={() => {
                if (isLogin) {
                  navigate("/profile");
                } else {
                  navigate("/login");
                }
              }}
            >
              Register
            </button>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-black hover:text-white hover:bg-[#EB5757]  transition-all duration-150 delay-150 ease-linear focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`fixed top-0 z-20 left-0 w-64 h-full bg-white transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out md:hidden`}
      >
        <div className="flex items-center justify-between px-4">
          <div className="flex flex-row items-center gap-2">
            <img className="w-24 h-24" src="/logo.svg" alt="Logo" />
            {/* <p>Askitall!</p> */}
          </div>
          <button
            onClick={() => setIsOpen(false)}
            className="text-gray-400 hover:text-black"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="px-2 pt-2 pb-3 space-y-1">
          <a
            href="#"
            className="block px-3 py-2 text-base font-medium text-black rounded-md"
          >
            Home
          </a>
          <a
            href="#"
            className="block px-3 py-2 text-base font-medium text-black rounded-md"
          >
            About
          </a>
          <a
            href="#"
            className="block px-3 py-2 text-base font-medium text-black rounded-md"
          >
            Services
          </a>
          <a
            href="/contact"
            className="block px-3 py-2 text-base font-medium text-black rounded-md"
          >
            Contact
          </a>
        </div>
        <div className="px-4 mt-4">
          <button
            onClick={() =>
              (window.location.href = "https://askitforall.vercel.app/login")
            }
            className="w-full bg-[#EB5757] hover:bg-[#EB5757] text-white px-4 py-2 rounded-md text-sm font-medium"
          >
            Register
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavbarWebsite1;
