import React, { useState, useEffect } from "react";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import Layout from "../HomePage/Layout";
import { urls } from "../../api/apiUrl";
import { makeApiCall } from "../../api/config";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

const daysMap = {
  SUN: "Sunday",
  MON: "Monday",
  TUE: "Tuesday",
  WED: "Wednesday",
  THU: "Thursday",
  FRI: "Friday",
  SAT: "Saturday",
};

const ScheduleSettings = () => {
  let authData = JSON.parse(localStorage.getItem("tokens"));
  const [isLoading, setIsLoading] = useState(false);
  const [dayToggles, setDayToggles] = useState({});
  const [timeSlots, setTimeSlots] = useState({});
  const [slotsData, setSlotsData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [daysWithDates, setDaysWithDates] = useState({});

  const [currentMonth, setCurrentMonth] = useState(new Date());

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [selectedDates, setSelectedDates] = useState({});

  const handleDateClick = (day, date) => {
    const selectedDate = new Date(date);
    selectedDate.setHours(0, 0, 0, 0); // Set to midnight in local time

    const normalizedDate = `${selectedDate.getFullYear()}-${(
      selectedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${selectedDate.getDate().toString().padStart(2, "0")}`;

    setSelectedDates((prevSelected) => {
      const dayDates = prevSelected[day] || [];
      const isSelected = dayDates.includes(normalizedDate);

      return {
        ...prevSelected,
        [day]: isSelected
          ? dayDates.filter((d) => d !== normalizedDate) // Deselect date
          : [...dayDates, normalizedDate], // Select date
      };
    });
  };

  console.log("selecteddddd", selectedDates);

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => {
      const nextMonth = new Date(prevMonth);
      nextMonth.setMonth(prevMonth.getMonth() + 1);
      setIsButtonDisabled(true);
      return nextMonth;
    });
  };

  const handlePreviousMonth = () => {
    setCurrentMonth((prevMonth) => {
      const previousMonth = new Date(prevMonth);
      previousMonth.setMonth(prevMonth.getMonth() - 1);
      setIsButtonDisabled(false);
      return previousMonth;
    });
  };

  const getDatesForDay = (dayOfWeek, month = currentMonth) => {
    const dates = [];
    const currentYear = month.getFullYear();
    let date = new Date(currentYear, month.getMonth(), 1);

    while (date.getDay() !== dayOfWeek) {
      date.setDate(date.getDate() + 1);
    }

    while (date.getMonth() === month.getMonth()) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 7);
    }

    return dates;
  };

  useEffect(() => {
    const newDaysWithDates = {};
    Object.keys(daysMap).forEach((day, index) => {
      newDaysWithDates[day] = getDatesForDay(index);
    });
    setDaysWithDates(newDaysWithDates);
  }, [currentMonth]);

  useEffect(() => {
    const newDaysWithDates = {};
    Object.keys(daysMap).forEach((day, index) => {
      newDaysWithDates[day] = getDatesForDay(index);
    });
    setDaysWithDates(newDaysWithDates);
  }, []);

  const handleToggle = (day) => {
    setDayToggles((prev) => {
      const newToggles = { ...prev, [day]: !prev[day] };
      if (newToggles[day] && !timeSlots[day]) {
        setTimeSlots((prevSlots) => ({
          ...prevSlots,
          [day]: [{ start: "09:00", end: "17:00" }],
        }));
      }
      return newToggles;
    });
  };

  const handleTimeChange = (day, index, field, value) => {
    setTimeSlots((prev) => {
      const newSlots = [...prev[day]];
      newSlots[index] = { ...newSlots[index], [field]: value };
      return { ...prev, [day]: newSlots };
    });
  };

  const convert12HourTo24Hour = (time) => {
    let [hour, minute] = time.match(/(\d+):(\d+)/).slice(1, 3);
    const ampm = time.includes("PM") ? "PM" : "AM";

    if (ampm === "PM" && hour !== "12") {
      hour = String(parseInt(hour, 10) + 12);
    } else if (ampm === "AM" && hour === "12") {
      hour = "00";
    }

    return `${hour.padStart(2, "0")}:${minute}`;
  };

  const getConsultantSlots = async () => {
    setIsLoading(true);
    try {
      const { slots } = await makeApiCall(
        "GET",
        urls.consultantSlots,
        null,
        authData?.access?.token
      );
      setSlotsData(slots);

      const newTimeSlots = {};
      const newDayToggles = {};
      const newSelectedDates = {};

      slots.forEach((slotData) => {
        const dayName = Object.keys(daysMap).find(
          (key) => daysMap[key] === slotData.day
        );

        if (dayName) {
          const firstSlot = convert12HourTo24Hour(slotData.slots[0].slot);
          const lastSlot = convert12HourTo24Hour(
            slotData.slots[slotData.slots.length - 1].slot
          );

          newTimeSlots[dayName] = [
            { id: slotData?._id, start: firstSlot, end: lastSlot },
          ];
          newDayToggles[dayName] = true;

          if (!newSelectedDates[dayName]) {
            newSelectedDates[dayName] = [];
          }

          slotData.slots.forEach((slot) => {
            const date = slot.date;
            if (!newSelectedDates[dayName].includes(date)) {
              newSelectedDates[dayName].push(date);
            }
          });
        }
      });

      setTimeSlots(newTimeSlots);
      setDayToggles(newDayToggles);
      setSelectedDates(newSelectedDates);
    } catch (error) {
      console.error("Error fetching consultant slots:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getConsultantSlots();
  }, []);

  const handleSubmit = async () => {
    let formattedData;
    const activeDays = Object.keys(timeSlots).filter(
      (day) => dayToggles[day] === true
    );

    if (slotsData.length > 0) {
      formattedData = {
        data: activeDays.map((day) => ({
          id: timeSlots[day][0]?.id,
          day: daysMap[day],
          slots: timeSlots[day].map((slot) => ({
            from: slot.start,
            to: slot.end,
          })),
          selectedDates: selectedDates[day] || [],
        })),
        minutes: 10,
      };
    } else {
      formattedData = {
        data: activeDays.map((day) => ({
          day: daysMap[day],
          slots: timeSlots[day].map((slot) => ({
            from: slot.start,
            to: slot.end,
          })),
          selectedDates: selectedDates[day] || [],
        })),
        minutes: 10,
      };
    }

    try {
      await makeApiCall(
        "POST",
        slotsData.length > 0 ? urls.rescheduleSlots : urls.scheduleSlots,
        formattedData,
        authData?.access?.token
      );
      getConsultantSlots();
      setEditMode(false);
      toast.success("Availability updated successfully!");
    } catch (error) {
      toast.error("Failed to update availability!");
    }
  };

  return (
    <Layout>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="max-w-5xl p-6 mx-auto overflow-hidden ">
          <div className="flex items-center justify-between mb-10">
            <h2 className="text-2xl font-semibold text-rose-500">
              Availability
            </h2>
            {!editMode && (
              <button
                onClick={() => setEditMode(true)}
                className=" text-[#EB5757] hover:bg-gray-100 py-2 px-4 transition-all duration-150 ease-linear rounded-md"
              >
                <PencilIcon className="w-5 h-5" />
              </button>
            )}
          </div>

          <div className="flex items-center justify-between">
            <span className="text-lg font-medium text-black">
              {currentMonth.toLocaleString("default", { month: "long" })}{" "}
              {currentMonth.getFullYear()}
            </span>

            {isButtonDisabled ? (
              <button onClick={handlePreviousMonth}>Previous Month</button>
            ) : (
              <button onClick={handleNextMonth} disabled={isButtonDisabled}>
                Next Month
              </button>
            )}
          </div>

          <div className="mt-6 space-y-6">
            {Object.keys(daysMap).map((day) => (
              <div
                key={day}
                className="flex flex-col justify-between sm:flex-row sm:items-center"
              >
                <div className="flex items-center w-full mb-2 space-x-2 sm:mb-0">
                  <label
                    htmlFor={`toggle-${day}`}
                    className="relative inline-flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id={`toggle-${day}`}
                      disabled={!editMode}
                      className="sr-only peer"
                      checked={dayToggles[day] || false}
                      onChange={() => handleToggle(day)}
                    />
                    <div className="w-11 h-6 bg-[#c8c9ca] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-0 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#EB5757]"></div>
                  </label>
                  <div className="flex items-center w-full gap-10">
                    <div className="w-[10%] ">
                      <span className="text-base font-medium text-black ">
                        {day}
                      </span>
                    </div>
                    <div className="flex items-center gap-3 ">
                      {dayToggles[day] &&
                        daysWithDates[day]?.map((date) => {
                          const selectedDate = new Date(date);
                          selectedDate.setHours(0, 0, 0, 0);

                          const normalizedDate = `${selectedDate.getFullYear()}-${(
                            selectedDate.getMonth() + 1
                          )
                            .toString()
                            .padStart(2, "0")}-${selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0")}`;

                          const isSelected =
                            selectedDates[day]?.includes(normalizedDate);

                          return (
                            <span
                              key={date}
                              onClick={() => {
                                if (editMode) {
                                  handleDateClick(day, date);
                                }
                              }}
                              className={`p-2 py-[2px] text-base text-black rounded-md ${
                                editMode ? "cursor-pointer" : "cursor-auto"
                              } ${
                                isSelected
                                  ? "bg-[#EB5757] text-white"
                                  : "bg-gray-200"
                              }`}
                            >
                              {new Date(date).getDate()}
                            </span>
                          );
                        })}
                    </div>
                  </div>
                </div>
                {dayToggles[day] && (
                  <div className="flex flex-col space-y-2">
                    {timeSlots[day]?.map((slot, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <input
                          type="time"
                          value={slot.start}
                          disabled={!editMode}
                          onChange={(e) =>
                            handleTimeChange(
                              day,
                              index,
                              "start",
                              e.target.value
                            )
                          }
                          className="w-32 px-2 py-1 border rounded"
                        />
                        <span>-</span>
                        <input
                          type="time"
                          value={slot.end}
                          disabled={!editMode}
                          onChange={(e) =>
                            handleTimeChange(day, index, "end", e.target.value)
                          }
                          className="w-32 px-2 py-1 border rounded"
                        />
                        {/* {editMode && (
                        <button
                          onClick={() => removeTimeSlot(day, index)}
                          className="text-red-500 hover:text-red-600"
                          aria-label={`Remove time slot for ${day}`}
                        >
                          <TrashIcon className="w-5 h-5" />
                        </button>
                      )} */}
                      </div>
                    ))}
                    {/* {editMode && (
                    <button
                      onClick={() => addTimeSlot(day)}
                      className="flex items-center text-blue-500 hover:text-blue-600"
                    >
                      <PlusIcon className="w-5 h-5 mr-1" />
                      Add time slot
                    </button>
                  )} */}
                  </div>
                )}
              </div>
            ))}
          </div>
          {/* 
          <div className="grid grid-cols-1 gap-4 mt-10 sm:grid-cols-2">
            <div className="space-y-2">
              <label
                htmlFor="max-duration"
                className="block mb-1 text-sm font-medium text-gray-700"
              >
                Max. Sessions duration (minutes)
              </label>
              <input
                type="number"
                id="max-duration"
                disabled={!editMode}
                value={maxDuration}
                onChange={(e) => setMaxDuration(Number(e.target.value))}
                className="w-3/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
            </div>
          </div> */}
          {editMode && (
            <div className="flex items-center justify-end gap-4 mt-8">
              <button
                onClick={() => setEditMode(false)}
                className="bg-transparent text-sm text-[#EB5757] hover:bg-gray-100 py-2 px-4 transition-all duration-150 ease-linear rounded-md   "
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="bg-[#EB5757] text-sm text-white py-2 px-8 rounded-md  transition-all duration-150 ease-linear hover:bg-red-100 hover:text-[#EB5757] "
              >
                {slotsData?.length > 0 ? "Save" : "Create"}
              </button>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default ScheduleSettings;
