import React from "react";
import { useNavigate } from "react-router";
import { isLoggedIn } from "../../utils/helper";

const Hero = () => {
  const navigate = useNavigate();
  const isLogin = isLoggedIn();

  const handleNavigate = () => {
    if(isLogin){
      navigate('/advisors');
    }
    else{
      navigate('/public/advisors')
    }
  }

  return (
    <section className="bg-gray-100">
      <div className="w-full max-w-screen-xl py-8 m-auto md:py-20">
        <div className="flex flex-col items-start justify-center px-2 mx-auto lg:pl-8 md:flex-row ">
          {/* Text content */}
          <div className="flex flex-col items-start justify-start mb-8 md:w-1/2 gap-y-0 lg:mb-8 md:mb-0">
            <div>
              <h1 className="text-3xl font-bold leading-8 md:text-5xl lg:leading-snug ">
                Ask.Connect.Grow.
              </h1>
            </div>
            <div>
              <p className="max-w-xl text-gray-600">
                Struggling to find trusted advisors?
                <br />
                <br/>
                <br/>
                Ask It All guides andprovides you a way ahead in this journey.
                We connect you with SEBI-registered analysts & advisors who will
                answer all your queries. Stop running in circles for your
                financial & legal doubts, Book one-on-one video calls to gain
                the financial insights & legal guidance you deserve, all at
                affordable rates
              </p>
            </div>
            <div className="flex flex-col items-start gap-4 lg:items-center lg:flex-row ">
              <div className="flex -space-x-4 rtl:space-x-reverse">
                <img
                  className="w-10 h-10 rounded-full "
                  src="/assets/dummypfp/User.svg"
                  alt=""
                />
                <img
                  className="w-10 h-10 rounded-full "
                  src="/assets/dummypfp/User-1.svg"
                  alt=""
                />
                <img
                  className="w-10 h-10 rounded-full "
                  src="/assets/dummypfp/User-2.svg"
                  alt=""
                />
                <img
                  className="w-10 h-10 rounded-full "
                  src="/assets/dummypfp/User-3.svg"
                  alt=""
                />
                <img
                  className="w-10 h-10 rounded-full "
                  src="/assets/dummypfp/User-4.svg"
                  alt=""
                />
                <img
                  className="w-10 h-10 rounded-full "
                  src="/assets/dummypfp/User-5.svg"
                  alt=""
                />
              </div>
              <div className="flex flex-col items-start justify-start">
                <div>
                  <p className="font-normal text-black">Rated by Learners</p>
                </div>
                <div className="flex items-center gap-2">
                  <img
                    src="/assets/star.svg"
                    width={18}
                    height={18}
                    alt="star logo"
                  />
                  <p className="font-semibold text-black ">4.8/5</p>
                  <img
                    src="/assets/blackdot.svg"
                    width={5}
                    height={5}
                    alt="arrow up"
                  />
                  <p className="font-normal text-black">
                    80,000+ with 99% Pass Rate
                  </p>
                </div>
              </div>
            </div>
            <div className="py-3">
              <button onClick={handleNavigate} className="w-full flex items-center gap-2 bg-[#EB5757] hover:bg-[#EB5757] text-white px-6 py-2 rounded-md text-sm font-medium">
                Book an advisor
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-move-right"
                >
                  <path d="M18 8L22 12L18 16" />
                  <path d="M2 12H22" />
                </svg>
              </button>
            </div>
          </div>

          {/* Image content */}

          <div className="relative w-full lg:w-1/2">
            <img
              src="/bg.png"
              alt="Learning Platform"
              className="z-10 w-full lg:w-4/5"
            />
            {/* <img src="https://via.placeholder.com/300x200" alt="Students" className="absolute w-2/3 rounded-lg shadow-lg -bottom-10 -left-10" /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
