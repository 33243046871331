import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { isUserMentor } from "../../../utils/helper";

const Sidebar = ({ closeSidebar }) => {
  const location = useLocation();
  const { user } = useSelector((state) => state.user);

  const isMentor = isUserMentor();

  return (
    <div className="w-[250px]  bg-white h-screen shadow-lg flex flex-col  transition-all duration-300 ease-in-out lg:translate-x-0 ">
      <div className="flex items-center justify-between px-10 pt-4 ">
        <div className="flex flex-col items-center">
          {/* <h1 className="text-xl font-bold bg-gradient-to-r from-[#EB5757] via-[#4A3AFF] to-[#4A3AFF] bg-clip-text text-transparent">
            ASK-IT-ALL
          </h1> */}
          <Link to="/">
            <img
              className="w-[120px] h-[40px] object-contain"
              src="/logo.svg"
              alt="Logo"
            />
          </Link>
        </div>
        <button onClick={closeSidebar} className="lg:hidden">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <nav className="flex flex-col justify-between h-screen px-6 py-2">
        <div>
          <h2 className="px-4 py-4 text-base font-semibold text-black">
            OVERVIEW
          </h2>
          <ul className="flex flex-col gap-3">
            {isMentor
              ? [
                  "Profile",
                  "Accept Meets",
                  "Bookings",
                  "History",
                  "Schedule",
                ].map((item) => (
                  <li
                    key={item}
                    className={`px-4 py-2 rounded-md hover:bg-gray-100 ${
                      item === "Accept Meets"
                        ? location.pathname.includes("/accept")
                          ? "bg-gray-100"
                          : ""
                        : location.pathname.includes(item.toLowerCase())
                        ? "bg-gray-100"
                        : ""
                    }`}
                  >
                    <Link
                      to={
                        item === "Profile"
                          ? "/profile"
                          : item === "Bookings"
                          ? "/bookings"
                          : item === "Credits"
                          ? "/credits"
                          : item === "History"
                          ? "/history"
                          : item === "Schedule"
                          ? "/schedule"
                          : "/accept"
                      }
                      className={`flex items-center gap-x-2 text-base font-medium ${
                        item === "Accept Meets"
                          ? location.pathname.includes("/accept")
                            ? "text-[#EB5757]"
                            : "text-gray-700"
                          : location.pathname.includes(item.toLowerCase())
                          ? "text-[#EB5757]"
                          : "text-gray-700"
                      }`}
                    >
                      <img
                        src={
                          item === "Accept Meets"
                            ? location.pathname.includes("/accept")
                              ? getActiveIcon(item)
                              : getIcon(item)
                            : location.pathname.includes(item.toLowerCase())
                            ? getActiveIcon(item)
                            : getIcon(item)
                        }
                        className="w-4"
                        alt={`${item} icon`}
                      />
                      {item}
                    </Link>
                  </li>
                ))
              : ["Profile", "Bookings", "Advisors", "Credits"].map((item) => (
                  <li
                    key={item}
                    className={`px-4 py-2 rounded-md hover:bg-gray-100 ${
                      location.pathname.includes(item.toLowerCase())
                        ? "bg-gray-100"
                        : ""
                    }`}
                  >
                    <Link
                      to={
                        item === "Profile"
                          ? "/profile"
                          : item === "Bookings"
                          ? "/bookings"
                          : item === "Credits"
                          ? "/credits"
                          : "/advisors"
                      }
                      className={`flex items-center gap-x-2 text-base font-medium ${
                        location.pathname.includes(item.toLowerCase())
                          ? "text-[#EB5757]"
                          : "text-gray-700"
                      }`}
                    >
                      <img
                        src={
                          location.pathname.includes(item.toLowerCase())
                            ? getActiveIcon(item)
                            : getIcon(item)
                        }
                        className="w-4"
                        alt={`${item} icon`}
                      />
                      {item}
                    </Link>
                  </li>
                ))}
          </ul>
          {/* <div>
            <h2 className="px-4 py-2 mt-4 text-sm font-semibold text-black">
              PREVIOUS MENTORS
            </h2>
            <ul>
              {Mentors.map((mentor) => (
                <li key={mentor.title} className="px-4 py-2 hover:bg-gray-100">
                  <a href="#" className="flex items-center text-gray-700">
                    <img
                      src={mentor.img}
                      alt={mentor}
                      className="w-6 h-6 mr-2 rounded-full"
                    />
                    {mentor.title}
                  </a>
                </li>
              ))}
            </ul>
          </div> */}
        </div>

        <div>
          {/* <h2 className="px-4 py-2 mt-4 text-base font-semibold text-black">
            SETTINGS
          </h2> */}
          <ul>
            {["Logout"].map((item) => (
              <li key={item} className="px-4 py-2 hover:bg-gray-100">
                <Link
                  onClick={
                    item === "Logout" ? () => localStorage.clear() : null
                  }
                  to={item === "Logout" ? "/" : "#"}
                  className={`flex items-center text-base font-medium gap-x-2 ${
                    item === "Logout" ? "text-red-500" : "text-gray-700"
                  }`}
                >
                  <img src={getIcon(item)} className="w-4 fill-[#EB5757]" />
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

const getIcon = (item) => {
  // You can replace these with actual icons
  switch (item) {
    case "Profile":
      return "/assets/sidebar/inactiveHome.svg";
    case "Bookings":
      return "/assets/sidebar/bookings.svg";
    case "Advisors":
      return "/assets/sidebar/history.svg";
    case "Credits":
      return "/assets/sidebar/payment.svg";
    case "History":
      return "/assets/sidebar/payment.svg";
    case "Report":
      return "/assets/sidebar/report.svg";
    case "Settings":
      return "/assets/sidebar/settings.svg";
    case "Logout":
      return "/assets/sidebar/logout.svg";
    case "Accept Meets":
      return "/assets/sidebar/accept.svg";
    case "Schedule":
      return "/assets/sidebar/schedule.svg";
    default:
      return "•";
  }
};

const getActiveIcon = (item) => {
  switch (item) {
    case "Profile":
      return "/assets/sidebar/home.svg";
    case "Bookings":
      return "/assets/sidebar/activeBooking.svg";
    case "Advisors":
      return "/assets/sidebar/people.svg";
    case "Credits":
      return "/assets/sidebar/activeAdvisor.svg";
    case "History":
      return "/assets/sidebar/activeAdvisor.svg";
    case "Settings":
      return "/assets/sidebar/activeSettings.svg";
    case "Logout":
      return "/assets/sidebar/logout.svg";
    case "Accept Meets":
      return "/assets/sidebar/activeAccept.svg";
    case "Schedule":
      return "/assets/sidebar/activeSchedule.svg";
    default:
      return "•";
  }
};

export default Sidebar;
